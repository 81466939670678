import imgSlide from './../../images/dl-img-1.png';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import { Carousel } from 'react-responsive-carousel';

export default function Marketing() {
    return (
        <div className="bg-dl-screen-lightblue py-8 lg:py-16">
            <div className="relative mx-auto px-4 sm:px-6 lg:px-8">
                <div className="relative py-24 px-8 bg-dl-primary-700 rounded-xl shadow-2xl overflow-hidden lg:px-16 lg:grid lg:grid-cols-2 lg:gap-x-8">
                    <div className="absolute inset-0 opacity-50 filter saturate-0 mix-blend-multiply">
                        <img
                            src={imgSlide}
                            alt=""
                            className="w-full h-full object-cover"
                        />
                    </div>

                    <Carousel showStatus={false} showIndicators={false} showThumbs={false} autoPlay infiniteLoop interval={4000}>
                        <div>
                            <blockquote className="mt-6 text-white block w-full">
                                <h2 className="text-xl font-medium sm:text-3xl text-dl-accent">
                                    TUS PACIENTES MÁS CERCA DE TI / TUS PACIENTES A UN SOLO CLICK
                                </h2>
                                <p className="text-xl font-medium sm:text-2xl pt-3">
                                    <ul className="list-decimal">
                                        - Aumenta tu visibilidad y construye tu reputación online
                                    </ul>
                                    <ul className="list-decimal">- Ten el control total de tus citas</ul>
                                    <ul className="list-decimal">
                                        - Aporta un servicio digital y de calidad a tus pacientes
                                    </ul>
                                </p>
                            </blockquote>
                        </div>
                        <div>
                            <blockquote className="mt-6 text-white block w-full">
                                <h2 className="text-xl font-medium sm:text-3xl text-dl-accent">
                                    TU DOCTOR MÁS CERCA DE TI / TU DOCTOR A UN SOLO CLICK
                                </h2>
                                <p className="text-xl font-medium sm:text-2xl pt-3">
                                    <ul className="list-decimal">- Encuentra al medico que necesitas en un solo click</ul>
                                    <ul className="list-decimal">- Agenda tu cita de manera ágil y sencilla 24/7</ul>
                                    <ul className="list-decimal">- Ten el control y seguimiento de tus citas </ul>
                                </p>
                            </blockquote>
                        </div>
                    </Carousel>
                </div>
            </div>
        </div>
    );
}
