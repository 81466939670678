import { Fragment, useState } from 'react';
import { Popover, Transition } from '@headlessui/react';
import {
    BookmarkAltIcon,
    BriefcaseIcon,
    ChartBarIcon,
    CheckCircleIcon,
    CursorClickIcon,
    DesktopComputerIcon,
    GlobeAltIcon,
    InformationCircleIcon,
    MenuIcon,
    NewspaperIcon,
    OfficeBuildingIcon,
    PhoneIcon,
    PlayIcon,
    ShieldCheckIcon,
    UserGroupIcon,
    ViewGridIcon,
    XIcon,
} from '@heroicons/react/outline';
import { ChevronDownIcon } from '@heroicons/react/solid';
import {Link} from "react-router-dom";
import PopUpModal from "../modals/PopUpModal";

import hLogo from './../../images/dl-h-logo.png'

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function MainHeaderElement(props) {

    const {updateNavigationFunction} = props;
    const [open, setOpen] = useState(false);

    return (
        <Popover className="relative bg-white">
            <div className="absolute inset-0 shadow z-30 pointer-events-none" aria-hidden="true" />
            <div className="relative z-20">
                <div className="mx-auto flex justify-between items-center px-4 py-5 sm:px-6 sm:py-4 lg:px-8 md:justify-start md:space-x-10">
                    <div>
                        <Link to="/" className="flex">
                            <span className="sr-only">DoctoLink</span>
                            <img
                                className="h-8 w-auto sm:h-10"
                                src={hLogo}
                                alt=""
                            />
                        </Link>
                    </div>
                    <div className="-mr-2 -my-2 md:hidden">
                        <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-dl-primary-900">
                            <span className="sr-only">Abrir menú</span>
                            <MenuIcon className="h-6 w-6" aria-hidden="true" />
                        </Popover.Button>
                    </div>
                    <div className="hidden md:flex-1 md:flex md:items-center md:justify-between">
                        <Popover.Group as="nav" className="flex space-x-10">
                            
                            <Link
                                href="#"
                                className="text-base font-medium text-gray-500 hover:text-dl-accent"
                                to={'/'}
                            >
                                Inicio
                            </Link>
                            <Link
                                className="text-base font-medium text-gray-500 hover:text-dl-accent"
                                to={'/what-is-doctolink'}
                            >
                                ¿Qué es Doctolink?
                            </Link>
                            <Link
                                href="#"
                                className="text-base font-medium text-gray-500 hover:text-dl-accent"
                                to={'/who-we-are'}
                            >
                                ¿Quiénes somos?
                            </Link>
                            
                        </Popover.Group>
                        <div className="flex items-center md:ml-12">
                            <Link
                                to={'/sign-in'}
                                className="text-base font-medium text-gray-500 hover:text-dl-accent"
                            >
                                Iniciar sesión
                            </Link>
                            <Link
                                to="/sign-up"
                                className="ml-8 inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-full shadow-sm text-base font-medium text-white bg-dl-primary-900 hover:bg-dl-primary-700"
                            >
                                Registro
                            </Link>
                        </div>
                    </div>
                </div>
            </div>

            <Transition
                as={Fragment}
                enter="duration-200 ease-out"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="duration-100 ease-in"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
            >
                <Popover.Panel
                    focus
                    className="absolute z-30 top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden"
                >
                    <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50">
                        <div className="pt-5 pb-6 px-5 sm:pb-8">
                            <div className="flex items-center justify-between">
                                <div>
                                    <img
                                        className="h-8 w-auto"
                                        src={hLogo}
                                        alt="DoctoLink"
                                    />
                                </div>
                                <div className="-mr-2">
                                    <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-dl-primary-900">
                                        <span className="sr-only">Cerrar menú</span>
                                        <XIcon className="h-6 w-6" aria-hidden="true" />
                                    </Popover.Button>
                                </div>
                            </div> 
                        </div>
                        <div className="py-6 px-5">
                            <div className="grid grid-cols-2 gap-4">
                                
                                <Link
                                    href="#"
                                    className="rounded-md text-base font-medium text-gray-900 hover:text-dl-accent"
                                    to={'/'}
                                >
                                    Inicio
                                </Link>
                                <Link
                                    className="rounded-md text-base font-medium text-gray-900 hover:text-dl-accent"
                                    to={'/what-is-doctolink'}
                                >
                                    ¿Qué es Doctolink?
                                </Link>
                                <Link
                                    href="#"
                                    className="rounded-md text-base font-medium text-gray-900 hover:text-dl-accent"
                                    to={'/who-we-are'}
                                >
                                    ¿Quiénes somos?
                                </Link>

                                
                            </div>
                            <div className="mt-6">
                                <a
                                    href="#"
                                    className="w-full flex items-center justify-center px-4 py-2 border border-transparent rounded-full shadow-sm text-base font-medium text-white bg-dl-primary-900 hover:bg-dl-primary-700"
                                >
                                    Registro
                                </a>
                                <p className="mt-6 text-center text-base font-medium text-dl-accent">
                                    &iquest;Ya tienes cuenta de DoctoLink?{' '}
                                    <a href="#" className="text-dl-primary-900 hover:text-dl-primary-700">
                                        Iniciar sesión
                                    </a>
                                </p>
                            </div>
                        </div>
                    </div>
                </Popover.Panel>
            </Transition>
        </Popover>
    )
}
