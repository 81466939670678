import NavigationElements from "./pageElements/NavigationElements";
import AboutUsElement from "./pageElements/AboutUsElement";

export default function AboutUsPage(props) {

    const {displayNavigation, onUpdateDisplayNavigation} = props;

    return <>
        <NavigationElements
            displayNavigation={displayNavigation}
            onUpdateDisplayNavigation={onUpdateDisplayNavigation}
        />
        <AboutUsElement />
    </>
}
