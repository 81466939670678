/* eslint-disable no-unused-vars */
import {Fragment, useState} from 'react'
import {Disclosure, Listbox, Menu, Transition} from '@headlessui/react'
import {
    ArrowNarrowLeftIcon,
    ArrowNarrowRightIcon,
    BriefcaseIcon,
    CalendarIcon,
    CheckCircleIcon,
    CheckIcon,
    ChevronDownIcon,
    ChevronRightIcon,
    CurrencyDollarIcon,
    LinkIcon,
    LocationMarkerIcon,
    MailIcon,
    PencilIcon,
    SearchIcon
} from '@heroicons/react/solid'
import {BellIcon, MenuIcon, XIcon} from '@heroicons/react/outline'
import {Link} from "react-router-dom";

const user = {
    name: 'Whitney Francis',
    email: 'whitneyfrancis@example.com',
    imageUrl: 'https://images.unsplash.com/photo-1517365830460-955ce3ccd263?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80'
}
const navigation = [
    {
        name: 'Dashboard',
        href: '#',
        current: true
    }, {
        name: 'Jobs',
        href: '#',
        current: false
    }, {
        name: 'Applicants',
        href: '#',
        current: false
    }, {
        name: 'Company',
        href: '#',
        current: false
    },
]
const userNavigation = [
    {
        name: 'Your Profile',
        href: '#'
    }, {
        name: 'Settings',
        href: '#'
    }, {
        name: 'Sign out',
        href: '#'
    },
]
const tabs = [
    {
        name: 'Perfil',
        href: '#',
        current: true
    }, {
        name: 'Horarios',
        href: '#',
        current: false
    }, {
        name: 'Tarifas',
        href: '#',
        current: false
    }, {
        name: 'Info',
        href: '#',
        current: false
    },
]
const candidates = [
    {
        name: 'Dr. Nombre Apellido',
        area: 'Nombre de especialidad',
        imageUrl: 'https://images.unsplash.com/photo-1502685104226-ee32379fefbe?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
        address: 'Clínica 12, Alameda Juan Pablo II, Av. 15 norte, San Salvador'
    },
    // More candidates...
]

const profileTab = [
    {
        title: 'Generales',
        description: [
            {
                info: 'Fugiat ipsum ipsum deserunt culpa aute sint do nostrud anim incididunt cillum culpa consequat. Excepteur qui ipsum aliquip consequat sint. Sit id mollit nulla mollit nostrud in ea officia proident. Irure nostrud pariatur mollit ad adipisicing reprehenderit deserunt qui eu.',
            }, 
            {
                info: 'Fugiat ipsum ipsum deserunt culpa aute sint do nostrud anim incididunt cillum culpa consequat. Excepteur qui ipsum aliquip consequat sint. Sit id mollit nulla mollit nostrud in ea officia proident. Irure nostrud pariatur mollit ad adipisicing reprehenderit deserunt qui eu.',
            }
        ]
    },
    {
        title: 'Idiomas',
        description: [
            {
                info: 'Espanol',
            }, 
            {
                info: 'Ingles',
            }
        ]
    },
    // More profiles...
]

const publishingOptions = [
    {
        name: 'Published',
        description: 'This job posting can be viewed by anyone who has the link.',
        current: true
    }, {
        name: 'Draft',
        description: 'This job posting will no longer be publicly accessible.',
        current: false
    },
]

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function DoctorResultsTabsElement() {
    const [selected, setSelected] = useState(publishingOptions[0])

    return (
        <> {/*
        This example requires updating your template:

        ```
        <html className="h-full bg-white">
        <body className="h-full">
        ```
      */}
            <div className="min-h-full">
                {/* Navbar */}
                {/* TODO: ESTE ES EL NAVBAR ORIGINAL QUE TRAE, LO LOGICO ES QUITARLO PERO TAL VEZ LUEGO */}
                {/*<Disclosure as="nav" className="bg-gray-50">
                    {({ open }) => (
                        <>
                            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                                <div className="relative h-16 flex items-center justify-between border-b border-gray-200">
                                    <div className="flex items-center">
                                        <div className="flex-shrink-0">
                                            <img
                                                className="h-8 w-auto"
                                                src="https://tailwindui.com/img/logos/workflow-mark.svg?color=violet&shade=500"
                                                alt="Workflow"
                                            />
                                        </div>

                                         Links section
                                        <div className="hidden lg:block lg:ml-10">
                                            <div className="flex space-x-4">
                                                {navigation.map((item) => (
                                                    <a
                                                        key={item.name}
                                                        href={item.href}
                                                        className={classNames(
                                                            item.current ? 'bg-gray-100' : 'hover:text-gray-700',
                                                            'px-3 py-2 rounded-md text-sm font-medium text-gray-900'
                                                        )}
                                                        aria-current={item.current ? 'page' : undefined}
                                                    >
                                                        {item.name}
                                                    </a>
                                                ))}
                                            </div>
                                        </div>
                                    </div>

                                    <div className="flex-1 px-2 flex justify-center lg:ml-6 lg:justify-end">
                                         Search section
                                        <div className="max-w-lg w-full lg:max-w-xs">
                                            <label htmlFor="search" className="sr-only">
                                                Search
                                            </label>
                                            <div className="relative text-gray-400 focus-within:text-gray-500">
                                                <div className="pointer-events-none absolute inset-y-0 left-0 pl-3 flex items-center">
                                                    <SearchIcon className="h-5 w-5" aria-hidden="true" />
                                                </div>
                                                <input
                                                    id="search"
                                                    className="block w-full bg-white py-2 pl-10 pr-3 border border-gray-300 rounded-md leading-5 text-gray-900 placeholder-gray-500 focus:outline-none focus:ring-1 focus:ring-purple-500 focus:border-purple-500 focus:placeholder-gray-500 sm:text-sm"
                                                    placeholder="Search"
                                                    type="search"
                                                    name="search"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex lg:hidden">
                                         Mobile menu button
                                        <Disclosure.Button className="bg-gray-50 p-2 inline-flex items-center justify-center rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-purple-500">
                                            <span className="sr-only">Open main menu</span>
                                            {open ? (
                                                <XIcon className="block h-6 w-6" aria-hidden="true" />
                                            ) : (
                                                <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                                            )}
                                        </Disclosure.Button>
                                    </div>

                                     Actions section
                                    <div className="hidden lg:block lg:ml-4">
                                        <div className="flex items-center">
                                            <button
                                                type="button"
                                                className="bg-gray-50 flex-shrink-0 rounded-full p-1 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-purple-500"
                                            >
                                                <span className="sr-only">View notifications</span>
                                                <BellIcon className="h-6 w-6" aria-hidden="true" />
                                            </button>

                                             Profile dropdown
                                            <Menu as="div" className="ml-3 relative flex-shrink-0">
                                                <div>
                                                    <Menu.Button className="bg-gray-50 rounded-full flex text-sm text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-purple-500">
                                                        <span className="sr-only">Open user menu</span>
                                                        <img className="rounded-full h-8 w-8" src={user.imageUrl} alt="" />
                                                    </Menu.Button>
                                                </div>
                                                <Transition
                                                    as={Fragment}
                                                    enter="transition ease-out duration-100"
                                                    enterFrom="transform opacity-0 scale-95"
                                                    enterTo="transform opacity-100 scale-100"
                                                    leave="transition ease-in duration-75"
                                                    leaveFrom="transform opacity-100 scale-100"
                                                    leaveTo="transform opacity-0 scale-95"
                                                >
                                                    <Menu.Items className="origin-top-right absolute z-10 right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                                                        {userNavigation.map((item) => (
                                                            <Menu.Item key={item.name}>
                                                                {({ active }) => (
                                                                    <a
                                                                        href={item.href}
                                                                        className={classNames(
                                                                            active ? 'bg-gray-100' : '',
                                                                            'block py-2 px-4 text-sm text-gray-700'
                                                                        )}
                                                                    >
                                                                        {item.name}
                                                                    </a>
                                                                )}
                                                            </Menu.Item>
                                                        ))}
                                                    </Menu.Items>
                                                </Transition>
                                            </Menu>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <Disclosure.Panel className="bg-gray-50 border-b border-gray-200 lg:hidden">
                                <div className="px-2 pt-2 pb-3 space-y-1">
                                    {navigation.map((item) => (
                                        <Disclosure.Button
                                            key={item.name}
                                            as="a"
                                            href={item.href}
                                            className={classNames(
                                                item.current ? 'bg-gray-100' : 'hover:bg-gray-100',
                                                'block px-3 py-2 rounded-md font-medium text-gray-900'
                                            )}
                                            aria-current={item.current ? 'page' : undefined}
                                        >
                                            {item.name}
                                        </Disclosure.Button>
                                    ))}
                                </div>
                                <div className="pt-4 pb-3 border-t border-gray-200">
                                    <div className="px-5 flex items-center">
                                        <div className="flex-shrink-0">
                                            <img className="rounded-full h-10 w-10" src={user.imageUrl} alt="" />
                                        </div>
                                        <div className="ml-3">
                                            <div className="text-base font-medium text-gray-800">{user.name}</div>
                                            <div className="text-sm font-medium text-gray-500">{user.email}</div>
                                        </div>
                                        <button
                                            type="button"
                                            className="ml-auto bg-gray-50 flex-shrink-0 rounded-full p-1 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-purple-500"
                                        >
                                            <span className="sr-only">View notifications</span>
                                            <BellIcon className="h-6 w-6" aria-hidden="true" />
                                        </button>
                                    </div>
                                    <div className="mt-3 px-2 space-y-1">
                                        {userNavigation.map((item) => (
                                            <Disclosure.Button
                                                key={item.name}
                                                as="a"
                                                href={item.href}
                                                className="block rounded-md py-2 px-3 text-base font-medium text-gray-900 hover:bg-gray-100"
                                            >
                                                {item.name}
                                            </Disclosure.Button>
                                        ))}
                                    </div>
                                </div>
                            </Disclosure.Panel>
                        </>
                    )}
                </Disclosure>*/}

                {/* Page heading */}
                <header className="bg-dl-primary-900/[.1] py-8">
                    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 xl:flex xl:items-center xl:justify-between">
                        <div className="flex-1 min-w-0">
                            <nav className="flex" aria-label="Breadcrumb">
                                <ol role="list" className="flex items-center space-x-4">
                                    <li>
                                        <div>
                                            <a href="#" className="text-sm font-medium text-gray-500 hover:text-gray-700">
                                                Especialidad
                                            </a>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="flex items-center">
                                            <ChevronRightIcon className="flex-shrink-0 h-5 w-5 text-gray-400" aria-hidden="true"/>
                                            <a href="#" className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700">
                                                Nombre doctor
                                            </a>
                                        </div>
                                    </li>
                                </ol>
                            </nav>
                            <h1 className="mt-2 text-2xl font-bold leading-7 text-dl-accent sm:text-3xl sm:truncate">
                                Perfil doctor
                            </h1>

                            {/* Stacked list */}
                            <ul role="list" className="mt-5 border-t border-gray-200 divide-y divide-gray-200 sm:mt-0 sm:border-t-0">
                                {
                                candidates.map((candidate) => (
                                    <li key={
                                        candidate.email
                                    }>
                                        <Link to="/doctor-profile-details" className="group block">
                                            <div className="flex items-center py-5 px-4 sm:py-6 sm:px-0">
                                                <div className="min-w-0 flex-1 flex items-center">
                                                    <div className="flex-shrink-0">
                                                        <img className="h-40 w-40 rounded-full group-hover:opacity-75 border-2 border-dl-primary-500"
                                                            src={
                                                                candidate.imageUrl
                                                            }
                                                            alt=""/>
                                                    </div>
                                                    <div className="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4">
                                                        <div>
                                                            <p className="text-lg font-medium text-dl-accent truncate">
                                                                {
                                                                candidate.name
                                                            }</p>
                                                            <p className="mt-2 flex items-center text-sm text-gray-500">
                                                                <MailIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true"/>
                                                                <span className="truncate">
                                                                    {
                                                                    candidate.area
                                                                }</span>
                                                            </p>
                                                            <p className="mt-2 flex items-center text-sm text-gray-500">
                                                                <MailIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true"/>
                                                                <span className="">
                                                                    {
                                                                    candidate.address
                                                                }</span>
                                                            </p>
                                                        </div>
                                                        <div className=" md:block mx-auto mt-5">
                                                            <div>
                                                                <span className="sm:block">
                                                                    <button type="button" className="inline-flex items-center px-4 py-2 border border-transparent rounded-full shadow-sm text-base font-medium text-white bg-dl-primary-900 hover:bg-dl-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-dl-primary-700 focus:ring-dl-primary-700">
                                                                        Reservar una cita
                                                                    </button>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Link>
                                    </li>
                                ))
                            } </ul>

                            {/* <div className="mt-1 flex flex-col sm:flex-row sm:flex-wrap sm:mt-0 sm:space-x-8">
                                <div className="mt-2 flex items-center text-sm text-gray-500">
                                    <BriefcaseIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                                    Full-time
                                </div>
                                <div className="mt-2 flex items-center text-sm text-gray-500">
                                    <LocationMarkerIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                                    Remote
                                </div>
                                <div className="mt-2 flex items-center text-sm text-gray-500">
                                    <CurrencyDollarIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                                    $120k &ndash; $140k
                                </div>
                                <div className="mt-2 flex items-center text-sm text-gray-500">
                                    <CalendarIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                                    Closing on January 9, 2020
                                </div>
                            </div> */} 
                        </div>

                    </div>
                </header>

                <main className="pt-8 pb-16">
                    <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
                        <div className="px-4 sm:px-0">
                            <h2 className="text-lg font-medium text-dl-accent">
                                Datos generales
                            </h2>

                            {/* Tabs */}
                            <div className="sm:hidden">
                                <label htmlFor="tabs" className="sr-only">
                                    Select a tab
                                </label>
                                {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
                                <select id="tabs" name="tabs" className="mt-4 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-purple-500 focus:border-purple-500 sm:text-sm rounded-md"
                                    defaultValue={
                                        tabs.find((tab) => tab.current).name
                                }>
                                    {
                                    tabs.map((tab) => (
                                        <option key={
                                            tab.name
                                        }>
                                            {
                                            tab.name
                                        }</option>
                                    ))
                                } </select>
                            </div>
                            <div className="hidden sm:block">
                                <div className="border-b border-gray-200">
                                    <nav className="mt-2 -mb-px flex space-x-8" aria-label="Tabs">
                                        {
                                        tabs.map((tab) => (
                                            <a key={
                                                    tab.name
                                                }
                                                href={
                                                    tab.href
                                                }
                                                className={
                                                    classNames(tab.current ? 'border-dl-primary-500 text-dl-primary-900' : 'border-transparent text-dl-accent hover:text-gray-600 hover:border-gray-200', 'whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm')
                                            }>
                                                {
                                                tab.name
                                            }
                                                {
                                                tab.count ? (
                                                    <span className={
                                                        classNames(tab.current ? 'bg-purple-100 text-purple-600' : 'bg-gray-100 text-gray-900', 'hidden ml-2 py-0.5 px-2.5 rounded-full text-xs font-medium md:inline-block')
                                                    }>
                                                        {
                                                        tab.count
                                                    } </span>
                                                ) : null
                                            } </a>
                                        ))
                                    } </nav>
                                </div>
                            </div>
                        </div>

                        {/* Body tabs */}
                        {
                            profileTab.map((profile) => (
                                <div className="pt-8 px-4 sm:px-0">

                                    {/* Description list*/}
                                    <section aria-labelledby="applicant-information-title">
                                        <div className="bg-white shadow sm:rounded-lg">
                                            <div className="px-4 py-5 sm:px-6">
                                                <h2 id="applicant-information-title" className="text-lg leading-6 font-medium text-dl-secondary">
                                                    {profile.title}
                                                </h2>
                                                
                                            </div>
                                            <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                                                <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                                                    
                                                    <div className="sm:col-span-2"> 
                                                        <ul className='list-inside list-disc'>
                                                        {/* {profiles.info} */}
                                                            {
                                                                profile.description.map((profiles) => (
                                                                    <li className='mt-1 mb-3'>
                                                                        <span className="mt-1 text-sm text-gray-500">
                                                                            {profiles.info}
                                                                        </span>
                                                                    </li>
                                                                ) )
                                                            }
                                                        </ul>
                                                    </div> 
                                                </dl>
                                            </div> 
                                        </div>
                                    </section>
                                </div>
                            ))
                        }

                    </div>
                </main>
            </div>
        </>
    )
}
