import imgSlide from './../images/what-is-doctolink3.png';
import imgSlideSecond from './../images/what-is-doctolink2.png';

export default function WhatIsDoctolink() {
    return (
        <>
            <div className="w-full inline-table sm:h-[calc(100vh_-_306px)] relative bg-dl-screen-lightblue py-8 sm:py-24 lg:py-16">
                <div className="mx-auto max-w-md px-4 text-center sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl">
                    <h2 className="mt-2 text-3xl font-bold text-dl-secondary tracking-tight sm:text-3xl">
                        ¿Qué es Doctolink?
                    </h2>
                    <br></br>
                    <div className="relative py-20 px-8 bg-white rounded-xl shadow-2xl overflow-hidden lg:px-16 lg:grid lg:grid-cols-2 lg:gap-x-8">
                        <div className="absolute inset-0 filter saturate-10 mix-blend-multiply bg-dl-screen-lightblue">
                            <img src={imgSlide} className="w-full h-full object-cover" alt="" />
                        </div>
                        <div className="relative lg:col-span-1 text-left">
                            <blockquote className="mt-6 text-black">
                                <p className="text-xl font-medium sm:text-2xl pt-3">
                                    Innovamos la experiencia en el mundo de la salud de una manera amigable y accesible,
                                    creando una plataforma digital en la que el paciente y el médico estén conectados.
                                </p>
                            </blockquote>
                        </div>
                    </div>
                    <br></br>
                    <h2 className="mt-2 text-3xl font-bold text-dl-secondary tracking-tight sm:text-3xl">
                        ¿Cuál es nuestro objetivo?
                    </h2>
                    <br></br>
                    <div className="min-h-[324px] relative py-20 px-8 bg-white rounded-xl shadow-2xl overflow-hidden lg:px-16 lg:grid lg:grid-cols-2 lg:gap-x-8">
                        <div className="absolute inset-0 filter saturate-10 mix-blend-multiply bg-dl-screen-lightblue">
                            <img src={imgSlideSecond} className="w-full h-full object-cover" alt="" />
                        </div>
                        <div className="relative lg:col-span-1 text-left lg:absolute lg:right-0 lg:w-2/5 lg:mx-4 lg:mt-10 lg:mt-0 ">
                            <blockquote className="mt-6 text-black">
                                <p className="text-xl font-medium sm:text-2xl pt-3">
                                    Nuestra misión es ser la plataforma digital referente en la transformación del mundo
                                    de la salud.
                                </p>
                            </blockquote>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
