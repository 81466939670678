import { MailIcon, PhoneIcon } from '@heroicons/react/solid';
import RegularButton from "../uiElements/RegularButton";
import FilterElement from "./FilterElement";
import { Link } from "react-router-dom";
import SectionHeading from "../uiElements/SectionHeading";
import { CalendarIcon, CheckCircleIcon } from '@heroicons/react/outline';

export default function SearchResultsElement() {
    return (
        <>
            {/* <div className={'my-7'}>
                <RegularButton size={'large'} />
            </div>
            <div className={'my-7'}>
                <SectionHeading />
            </div> */}

            <div className={'my-3'}>
                <FilterElement />
            </div>
        </>
    )
}
