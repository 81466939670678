import { CalendarIcon, IdentificationIcon, KeyIcon, MailIcon, PhoneIcon, UsersIcon } from "@heroicons/react/outline";
import FormInputField from "../../common/components/formElements/FormInputField";
import FullRow from "../../common/components/formElements/FullRow";
import HalfRow from "../../common/components/formElements/HalfRow";
import useInitialForm from "./hooks/useInitialForm";
import { Controller } from "react-hook-form";
import FormComboBox from "../../common/components/formElements/FormComboBox";
import ProfileFormHeader from '../../doctorUser/components/ProfileFormHeader/ProfileFormHeader';
import CustomRedAlert from "../../common/components/customRedAlert/CustomRedAlert";
import CustomSuccessModal from "../../common/components/customSuccessModal/CustomSuccessModal";
import usePasswordForm from "./hooks/usePasswordForm";

const PersonalProfileForm = ({ userProfile, userId, userType }) => {
    const {
        formattedCountryData,
        handleSubmit,
        errors,
        register,
        control,
        saveProfileForm,
        personalDocuments,
        showErrorModal,
        // isLoading,
        showSuccessModal,
        onCloseSuccessMessage,
        onCloseErrorMessage
    } = useInitialForm(userType, userProfile, userId);

    const {  
        register: registerPassword,
        handleSubmit: handlePasswordSubmit,
        errors: passwordErrors,
        validatePasswordConfirmation,
        // isLoading,
        showErrorModal: showErrorModalPassword,
        showSuccessModal: showSuccessModalPassword,
        onUpdatePassword,
        onCloseErrorModal: onCloseErrorModalPassword,
        onCloseSuccessModal: onCloseSuccessModalPassword
    }= usePasswordForm(userType);

    const showError = showErrorModal || showErrorModalPassword;
    const showSuccess = showSuccessModal || showSuccessModalPassword;
    const errorMsg = showErrorModal 
      ? 'No se pudo obtener información válida para guardar. Inténtelo de nuevo.'
      : 'No se pudo cambiar de contraseña. revise la información ingresada e inténtelo de nuevo';
    const successMsg = showSuccessModal ?  'Perfil actualizado con éxito' : 'Contraseña actualizada con éxito';

    const closeSuccess = () => {
       showSuccessModal ? onCloseSuccessMessage() : onCloseSuccessModalPassword();
    };

    const closeError = () => {
       showErrorModal ? onCloseErrorMessage() : onCloseErrorModalPassword();
    };

    return (<>
      <form onSubmit={handleSubmit(saveProfileForm)}>
        <div className="shadow sm:rounded-md sm:overflow-hidden">
            <div className="bg-white py-6 px-4 space-y-6 sm:p-6">
                <ProfileFormHeader
                    title={'Perfil personal'}
                />
                <div>
                    <h3 className='text-lg leading-6 font-medium text-dl-secondary'>Datos generales</h3>
                </div>
                <div className="grid grid-cols-6 gap-6">
                    <HalfRow>
                        <FormInputField
                            identifier='firstName'
                            label='Nombres'
                            iconComponent={
                                <UsersIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                            }
                            type='string'
                            registration={register('firstName', {
                                required: true,
                            })}
                            disabled
                        />
                    </HalfRow>
                    <HalfRow>
                        <FormInputField
                            identifier='lastName'
                            label='Apellidos'
                            iconComponent={
                                <UsersIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                            }
                            type='string'
                            registration={register('lastName', {
                                required: true,
                            })}
                            disabled
                        />
                    </HalfRow>
                    <FullRow>
                        <FormInputField
                            identifier='phone'
                            label='Teléfono'
                            iconComponent={
                                <PhoneIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                            }
                            type='string'
                            registration={register('phone', {
                                required: true,
                            })}
                        />
                        {errors.phone
                            ? <p className="text-red-700 font-light">
                                Este campo no puede ser vacío
                            </p>
                            : null
                        }
                    </FullRow>
                </div>
            </div>
            <div className="bg-white py-6 px-4 space-y-6 sm:p-6">
                <div>
                    <h3 className='text-lg leading-6 font-medium text-dl-secondary'>
                        Documentación
                    </h3>
                </div>
                <div className="grid grid-cols-6 gap-6">
                    {userType === 'doctor' ? <FullRow>
                        <Controller
                            control={control}
                            name='nationality'
                            rules={{
                                validate: (value) => value.id !== 'default'
                            }}
                            render={({ field }) => (
                                <FormComboBox
                                    label='Nacionalidad'
                                    data={formattedCountryData}
                                    control={control}
                                    value={field.value}
                                    onChange={(value) => field.onChange(value)}
                                    disabled
                                />
                            )}
                        />
                    </FullRow> : null}
                    <HalfRow>
                        <FormInputField
                            identifier='personalDocument'
                            label='Documento de identificación'
                            iconComponent={
                                <IdentificationIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                            }
                            type='string'
                            registration={register('personalDocument', {
                                required: true,
                            })}
                            disabled
                        />
                    </HalfRow>
                    <HalfRow>
                        <FormInputField
                            identifier='selectedDocument'
                            label='Tipo de documento'
                            type='string'
                            value={personalDocuments[userProfile.documentType]}
                            disabled
                        />
                    </HalfRow>
                    <FullRow>
                        <FormInputField
                            identifier='birthDate'
                            label='Fecha de nacimiento'
                            iconComponent={
                                <CalendarIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                            }
                            type='string'
                            registration={register('birthDate', {
                                required: true,
                            })}
                            disabled
                        />
                    </FullRow>
                </div>
            </div>
            <div className="bg-white py-6 px-4 space-y-6 sm:p-6">
                <div>
                    <h3 className='text-lg leading-6 font-medium text-dl-secondary'>
                        Cuenta
                    </h3>
                </div>
                <div className="grid grid-cols-6 gap-6">
                    <FullRow>
                        <FormInputField
                            identifier='email'
                            label='Correo electrónico'
                            iconComponent={
                                <MailIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                            }
                            type='string'
                            registration={register('email', {
                                required: true,
                            })}
                            disabled
                        />
                    </FullRow>
                </div>
            </div>
            <div className='px-4 py-3 bg-gray-50 text-right sm:px-6'>
                <button
                    type='submit'
                    className='bg-dl-primary-900 border border-transparent rounded-full shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-dl-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-dl-primary-700'
                >
                    Actualizar perfil personal
                </button>
            </div>
        </div>
    </form>
    <form onSubmit={handlePasswordSubmit(onUpdatePassword)}>
        <div className="shadow sm:rounded-md sm:overflow-hidden">
            <div className="bg-white py-6 px-4 space-y-6 sm:p-6">
             <div className="bg-white py-6 px-4 space-y-6 sm:p-6">
                <div>
                    <h3 className='text-lg leading-6 font-medium text-dl-secondary'>Actualizar contraseña</h3>
                </div>
                <div className="grid grid-cols-6 gap-6">
                    <FullRow>
                        <FormInputField
                            identifier='oldPassowrd'
                            label='Contraseña actual'
                            iconComponent={
                                <KeyIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                            }
                            type='password'
                            registration={registerPassword('oldPassword', {
                                required: true,
                            })}
                        />
                         {passwordErrors.oldPassword?.type  === 'required'
                            ? <p className="text-red-700 font-light">
                               Inserte contraseña actual
                            </p>
                            : null
                        }
                    </FullRow>
                    <FullRow>
                        <FormInputField
                            identifier='newPassword'
                            label='Nueva contraseña'
                            iconComponent={
                                <KeyIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                            }
                            type='password'
                            registration={registerPassword('newPassword', {
                                required: true,
                            })}
                        />
                         {passwordErrors.newPassword?.type  === 'required'
                            ? <p className="text-red-700 font-light">
                               Inserte nueva contraseña
                            </p>
                            : null
                        }
                    </FullRow>
                    <FullRow>
                        <FormInputField
                            identifier='newPasswordConfirm'
                            label='Confirmar nueva contraseña'
                            iconComponent={
                                <KeyIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                            }
                            type='password'
                            registration={registerPassword('newPasswordConf', {
                                required: true,
                                validate: validatePasswordConfirmation
                            })}
                        />
                        {passwordErrors.newPasswordConf?.type  === 'required'
                            ? <p className="text-red-700 font-light">
                               Confirme contraseña
                            </p>
                            : null
                        }
                         {passwordErrors.newPasswordConf?.type  === 'validate'
                            ? <p className="text-red-700 font-light">
                               Las contraseñas deben coincidir
                            </p>
                            : null
                        }
                    </FullRow>
                    </div>
                </div>
            </div>
            <div className='px-4 py-3 bg-gray-50 text-right sm:px-6'>
                <button
                    type='submit'
                    className='bg-dl-primary-900 border border-transparent rounded-full shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-dl-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-dl-primary-700'
                >
                    Actualizar contraseña
                </button>
            </div>
        </div>
    </form>
    {showError && (
                <CustomRedAlert
                    open={showError}
                    title='Ocurrió un error.'
                    bodyText={errorMsg}
                    onClose={closeError}
                />
            )}

    {showSuccess && (
            <CustomSuccessModal
                title={successMsg}
                buttonText='OK'
                buttonAction={closeSuccess}
                open={showSuccess}
                onClose={closeSuccess}
            />
     )}
</>);
};

export default PersonalProfileForm;

