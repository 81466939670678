import {
    CalendarIcon,
    LocationMarkerIcon,
} from '@heroicons/react/outline'
import AppointmentCalendar from '../appointmentCalendar/AppointmentCalendar';
import FormTextArea from '../../../common/components/formElements/FormTextArea';
import useSelectAppointmentDate from '../../hooks/useSelectAppointmentDate';
import ErrorModal from '../../../common/components/customRedAlert/CustomRedAlert';
import { format, parseISO } from 'date-fns';

export default function CalendarAppointmentDateSelectionElement() {
    
    const {
        docData,
        availableAppointments,
        setSelectedAppointment,
        selectedAppointment,
        queryAppointmentHistory,
        additionalInfo,
        onSetAdditionalInfo,
        onCreateDoctorAppointment,
        onCloseErrorModal,
        error
    } = useSelectAppointmentDate();
    
    const parsedAppointment = selectedAppointment !== null
       ? parseISO(`${selectedAppointment.date}T${selectedAppointment.appTime}`)
       : null;

    return (<>
        <div className={'w-3/4'}>
             <div className="mb-4">
                        <div>
                            <div className="relative flex space-x-6 py-6 xl:static">
                                <img src={docData.personalImage} alt="doctor-image" className="h-14 w-14 flex-none rounded-full" />
                                <div className="flex-auto">
                                    <h3 className="pr-10 font-semibold text-gray-900 xl:pr-0">{docData.fullName}</h3>
                                    <dl className="mt-2 flex flex-col text-gray-500 xl:flex-row">
                                        <div className="flex items-start space-x-3">
                                            <dt className="mt-0.5">
                                                <CalendarIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                            </dt>
                                            <dd>{docData.speciality}</dd>
                                        </div>
                                        <div className="mt-2 flex items-start space-x-3 xl:mt-0 xl:ml-3.5 xl:border-l xl:border-gray-400 xl:border-opacity-50 xl:pl-3.5">
                                            <dt className="mt-0.5">
                                                <LocationMarkerIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                            </dt>
                                            <dd>{docData.address}</dd>
                                        </div>
                                    </dl>
                                </div>
                            </div>
                        </div>
                </div>
            <h2 className="text-lg font-semibold text-gray-900">Seleccione una fecha disponible para realizar la cita</h2>
            <div className="">
                  <AppointmentCalendar
                    selectedAppointment={selectedAppointment}
                    availableAppointments={availableAppointments} 
                    setSelectedAppointment={setSelectedAppointment}
                    queryAppointmentHistory={queryAppointmentHistory}
                  />
            </div>
            <div className='flex flex-col lg:mx-52'>
                {
                    parsedAppointment !== null ? 
                   ( <div className="bg-orange-100 border-l-4 border-orange-500 text-orange-700 p-4 mt-12" role="alert">
                   <p>Fecha seleccionada:</p>
                   <span className='font-bold'>{ `${new Date(selectedAppointment.date)
                   .toLocaleString('es', {
                           weekday: 'long',
                           year: 'numeric',
                           month: 'long',
                           day: 'numeric',
                       }
                   )}`
                   }
                   </span> {','}
                   <time className='font-bold' dateTime={parsedAppointment}>{format(parsedAppointment, 'h:mm a')}</time>
               </div>) : null
                }
               
                <div className='mt-12'>
                    <FormTextArea
                        label={'Información adicional'}
                        identifier={'educationDetail'}
                        rows={3}
                        value={additionalInfo}
                        onChange={onSetAdditionalInfo}
                        helperText='Requerido'
                        placeholder={
                                        'Agregue información adicional que considere importante para la cita'
                                    }
                                   
                       />
                </div>
                <div>
                    <button
                        disabled={parsedAppointment === null || additionalInfo === ''}
                        onClick={onCreateDoctorAppointment}
                        type="button"
                        className="disabled:opacity-75 focus:outline-none mt-8 w-full rounded-md border border-transparent bg-blue-600 py-2 px-4 text-sm font-medium text-white shadow hover:bg-blue-700 focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                    >
                            Agendar cita
                    </button>
                </div>
            </div>
        </div>
        <ErrorModal
           title='Ha ocurrido un error'
           bodyText={error}
           mainActionText='Cerrar'
           onMainAction={onCloseErrorModal}
           open={error !== ''} 
           onClose={onCloseErrorModal}
        />
     </>
    )
}
