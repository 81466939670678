import { useEffect, useState } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import useConfirmAccountDoctor from './hooks/useConfirmAccoutDoctor';
import SuccessNotification from '../common/components/successNotification/SuccessNotification';
import SuccessOverlay from '../common/components/successOverlay/SuccessOverlay';
import FailNotification from '../common/components/failNotification/FailNotification';

export default function ConfirmAccountDoctor() {
    const [displayWelcome, setDisplayWelcome] = useState(null);
    const [displaySuccessEmail, setDisplaySuccessEmail] = useState(false);
    const [displayErrorEmail, setDisplayErrorEmail] = useState(false);
    const [email, setEmail] = useState('');

    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const sendToLogin = () => navigate('/sign-in-doctor', { replace: true });

    const { executeValidation, sendNewEmail, noNeedEmail, setNoNeedEmail } = useConfirmAccountDoctor();
    const identifier = searchParams.get('t');

    useEffect(() => {
        const fetchValidationResult = async () => {
            if (identifier && displayWelcome === null) {
                const getExecution = await executeValidation(identifier);
                if (getExecution) setDisplayWelcome(true);
                if (!getExecution) setDisplayWelcome(false);
            }
        };

        fetchValidationResult();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const _requestNewEmail = () => {
        const sendNewEmailResult = sendNewEmail(email);

        if (sendNewEmailResult && sendNewEmailResult === 401) {
            setDisplaySuccessEmail(false);
            setDisplayErrorEmail(false);
            setTimeout(sendToLogin, 5000);
            return true;
        }

        if (sendNewEmailResult) {
            setDisplaySuccessEmail(true);
            setTimeout(sendToLogin, 5000);
            return true;
        }

        setDisplaySuccessEmail(false);
        setDisplayErrorEmail(true);
        return false;
    };

    return (
        <div className="bg-white h-[77vh]">
            <div className="mx-auto max-w-7xl py-12 px-4 text-center sm:px-6 lg:py-16 lg:px-8">
                <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                    <span className="block">Doctor(a), bienvenid@ a Doctolink!</span>
                    {displayWelcome && (
                        <p className="mt-4 text-lg leading-6">
                            Gracias por confirmar tu correo electrónico. Ya puedes iniciar sesión.
                        </p>
                    )}
                    {!displayWelcome && displayWelcome !== null && (
                        <p className="mt-4 text-lg leading-6 text-red-600">
                            Parece que ocurrió un problema con la validación de tu correo ¿Quieres volver a intentar?:
                        </p>
                    )}
                </h2>
                {displayWelcome && (
                    <div className="mt-8 flex justify-center">
                        <div className="ml-3 inline-flex">
                            <Link
                                to="/sign-in-doctor"
                                className="inline-flex items-center justify-center rounded-md border border-transparent bg-dl-primary-900 px-5 py-3 text-base font-medium text-white hover:bg-dl-primary-700"
                            >
                                Iniciar Sesión
                            </Link>
                        </div>
                    </div>
                )}

                {!displayWelcome && displayWelcome !== null && (
                    <div className="mt-8 flex justify-center">
                        <form className="mt-8 sm:flex sm:min-w-28 sm:shrink-0">
                            <label htmlFor="email-address" className="sr-only">
                                Email address
                            </label>
                            <input
                                id="email-address"
                                name="email"
                                type="email"
                                autoComplete="email"
                                value={email}
                                onInput={(event) => setEmail(event.target.value)}
                                required
                                className="w-full rounded-md border-gray-300 px-5 py-3 placeholder-gray-500 focus:border-blue-500 focus:ring-blue-500 sm:max-w-xs invalid:border-red-500"
                                placeholder="Enter your email"
                            />
                            <div className="mt-3 rounded-md shadow sm:mt-0 sm:ml-3 sm:flex-shrink-0">
                                <button
                                    type="button"
                                    onClick={_requestNewEmail}
                                    disabled={!email}
                                    className="disabled:opacity-50 disabled:bg-dl-primary-500 flex w-full items-center justify-center rounded-md border border-transparent bg-dl-primary-900 px-5 py-3 text-base font-medium text-white hover:bg-dl-primary-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                                >
                                    Enviar Validación
                                </button>
                            </div>
                        </form>
                    </div>
                )}
            </div>
            {displaySuccessEmail && !displayWelcome && !noNeedEmail && (
                <SuccessNotification
                    displayNotification={Boolean(displaySuccessEmail)}
                    setCloseNotification={() => setDisplaySuccessEmail(false)}
                    title={'Éxito!'}
                    bodyText={'El correo fue enviado correctamente.'}
                />
            )}

            {displayErrorEmail && !displayWelcome && !noNeedEmail && (
                <FailNotification
                    displayNotification={Boolean(displayErrorEmail)}
                    setCloseNotification={() => setDisplayErrorEmail(false)}
                    title={'Falla!'}
                    bodyText={'El correo No pudo ser enviado. Por favor inténtalo nuevamente.'}
                />
            )}

            {noNeedEmail && (
                <SuccessOverlay
                    open={Boolean(noNeedEmail)}
                    setClose={() => setNoNeedEmail(false)}
                    title={'Cuenta ya ha sido confirmada'}
                    bodyText={'Esta cuenta ya ha sido confirmada. Redirigiendo a la página de inicio de sesión.'}
                />
            )}
        </div>
    );
}
