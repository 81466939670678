import { useState } from 'react';
import fetchAuthenticatedUser from '../fetchAuthenticatedUser';
import { getCookie, removeCookie, setCookie } from '../utils/cookie';
import fetchAuthenticatedDoctor from '../fetchAuthenticatedDoctor';

const API_SERVER = process.env.REACT_APP_API_SERVER;

export default function useAuthentication() {
    const [authenticatedUser, setAuthenticatedUser] = useState(null);
    const [userInfo, setUserInfo] = useState(null);

    const checkIfLoggedIn = async () => {
        const getAuthUser = await fetchAuthenticatedUser();

        if (getAuthUser && getAuthUser.id && getAuthUser.usertype === 1) {
            setUserInfo(getAuthUser);
        } else {
            purge();
            setUserInfo(null);
        }

        return false;
    };

    const checkIfDoctorLoggedIn = async () => {
        const getAuthUser = await fetchAuthenticatedDoctor();

        if (getAuthUser && getAuthUser.id && getAuthUser.usertype === 2) {
            setUserInfo(getAuthUser);
        } else {
            purge();
            setUserInfo(null);
        }

        return false;
    };

    const purge = () => {
        removeCookie(process.env.REACT_APP_SESSION_NAME);
        removeCookie('usertype');
        setUserInfo(null);
    };

    const getUserType = () => {
        return getCookie('usertype');
    };

    const logoutUser = () => {
        purge();
        // window.location.replace(LOGIN);
    };

    const performUserLogin = async (userName, password) => {
        const attemptToLogin = await fetch(`${API_SERVER}/auth/signin`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ email: userName, password })
        });

        if (attemptToLogin && attemptToLogin.status < 400) {
            const loginResult = await attemptToLogin.json();
            setCookie(process.env.REACT_APP_SESSION_NAME, loginResult.access_token);
            setCookie('usertype', loginResult.usertype);
            setAuthenticatedUser(loginResult);
        }

        return attemptToLogin;
    };

    const performDoctorUserLogin = async (userName, password) => {
        const attemptToLogin = await fetch(`${API_SERVER}/doctor-data/signin`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ email: userName, password })
        });

        if (attemptToLogin && attemptToLogin.status < 400) {
            const loginResult = await attemptToLogin.json();
            setCookie(process.env.REACT_APP_SESSION_NAME, loginResult.access_token);
            setCookie('usertype', loginResult.usertype);
            setAuthenticatedUser(loginResult);
        }

        return attemptToLogin;
    };

    const performUserLogout = async () => {
        logoutUser();
    };

    const requestUserForgotPasswordRequest = async (userName) => {
        const attemptToRequestReset = await fetch(`${API_SERVER}/auth/request-password-reset?email=${userName}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        });

        if (attemptToRequestReset && attemptToRequestReset.status < 400) {
            return true;
        }

        return attemptToRequestReset;
    };

    const requestDoctorForgotPasswordRequest = async (userName) => {
        const attemptToRequestReset = await fetch(
            `${API_SERVER}/doctor-data/request-password-reset?email=${userName}`,
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            }
        );

        if (attemptToRequestReset && attemptToRequestReset.status < 400) {
            return true;
        }

        return attemptToRequestReset;
    };

    return {
        authenticatedUser,
        userInfo,
        checkIfLoggedIn,
        logoutUser,
        performUserLogin,
        performUserLogout,
        performDoctorUserLogin,
        checkIfDoctorLoggedIn,
        getUserType,
        requestUserForgotPasswordRequest,
        requestDoctorForgotPasswordRequest
    };
}
