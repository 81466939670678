const API_SERVER = process.env.REACT_APP_API_SERVER;

export const signUp = async (userType, data) => {
    const endpoint = userType === 'patient' ? '/auth/signup' : '/doctor-data/signup';
    const response = await fetch(`${API_SERVER}${endpoint}`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
    });

    const responseData = await response.json();

    if (!response.ok) {
        throw new Error(responseData.message);
    }

    return responseData;
};
