import { useState } from 'react';
import useDoctorUserProfile from '../../hooks/useDoctorUserProfile';
import CustomRedAlert from '../../../common/components/customRedAlert/CustomRedAlert';
import InitialProfileForm from './initialProfileForm/InitialProfileForm';
import DoctorProfileTabs from './doctorProfileTabs/DoctorProfileTabs';
import SecondarySpecialties from './secondarySpecialties/SecondarySpecialties';
import ProfileLanguages from './languages/ProfileLanguages';
import DoctorExperience from './experience/DoctorExperience';
import DoctorEducation from './education/DoctorEducation';
import DoctorMotives from './motives/DoctorMotives';
import DoctorSchedules from './schedules/DoctorSchedules';
import DoctorAvailability from './availability/DoctorAvailability';

export default function DoctorOwnProfile() {
    const {
        basicProfile,
        errorMessage,
        setErrorMessage,
        cities,
        isLoading,
        specialities,
        queryBasicProfile,
        secondarySpecialties,
        formattedSecondarySpecialties,
        querySecondarySpecialities,
        queryLanguagesInProfile,
        languagesInProfile,
        formattedLanguages,
        queryAllDoctorExperiences,
        experiences,
        queryAllDoctorEducation,
        education,
        motives,
        queryAllDoctorMotives,
        schedules,
        queryAllDoctorSchedules,
        availabilities,
        queryAllDoctorAvailabilities,
        schedulesAsOptions
    } = useDoctorUserProfile();

    const [currentComponent, setCurrentComponent] = useState('Perfil');

    return (
        <div className="space-y-6 sm:px-6 lg:px-0 lg:col-span-9 min-h-[calc(100vh_-_306px)] mb-56">
            {basicProfile && basicProfile.hasProfile && (
                <DoctorProfileTabs
                    selectedItem={currentComponent}
                    updateNavigation={(newComponent) => setCurrentComponent(newComponent)}
                />
            )}

            {!isLoading && currentComponent === 'Perfil' && (
                <InitialProfileForm
                    cities={cities}
                    basicProfile={basicProfile}
                    specialities={specialities}
                    queryBasicProfile={queryBasicProfile}
                />
            )}

            {!isLoading && currentComponent === 'Especialidades' && (
                <SecondarySpecialties
                    basicProfile={basicProfile}
                    secondarySpecialties={secondarySpecialties}
                    formattedSecondarySpecialties={formattedSecondarySpecialties}
                    querySecondarySpecialities={querySecondarySpecialities}
                />
            )}

            {!isLoading && currentComponent === 'Idioma' && (
                <ProfileLanguages
                    basicProfile={basicProfile}
                    languagesInProfile={languagesInProfile}
                    formattedLanguages={formattedLanguages}
                    queryLanguagesInProfile={queryLanguagesInProfile}
                />
            )}

            {!isLoading && currentComponent === 'Experiencia' && (
                <DoctorExperience
                    basicProfile={basicProfile}
                    experiences={experiences}
                    queryAllDoctorExperiences={queryAllDoctorExperiences}
                />
            )}

            {!isLoading && currentComponent === 'Educación' && (
                <DoctorEducation education={education} queryAllDoctorEducation={queryAllDoctorEducation} />
            )}

            {!isLoading && currentComponent === 'Motivos' && (
                <DoctorMotives motives={motives} queryAllDoctorMotives={queryAllDoctorMotives} />
            )}

            {!isLoading && currentComponent === 'Horarios' && (
                <DoctorSchedules schedules={schedules} queryAllDoctorSchedules={queryAllDoctorSchedules} />
            )}

            {!isLoading && currentComponent === 'Citas' && (
                <DoctorAvailability
                    schedulesAsOptions={schedulesAsOptions}
                    schedules={schedules}
                    queryAllDoctorAvailabilities={queryAllDoctorAvailabilities}
                    availabilities={availabilities}
                />
            )}

            {Boolean(errorMessage) && (
                <CustomRedAlert
                    open={true}
                    title={`Ocurrió un error.`}
                    bodyText={errorMessage}
                    onClose={() => setErrorMessage(null)}
                />
            )}
        </div>
    );
}
