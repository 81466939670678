import { Skeleton } from 'react-skeleton-generator';

const CalendarSkeleton = () => (<div className='w-full mt-12'>
    <Skeleton.SkeletonThemeProvider>
        <div className='flex flex-col md:flex-row justify-center gap-12 w-full'>
            <div>
                <div className='flex flex-row space-between gap-16'>
                    <Skeleton width='300px' />
                    <div className='flex flex-row space-between gap-4'>
                        <Skeleton width='30px' />
                        <Skeleton width='30px' />
                    </div>
                </div>
                <Skeleton height='400px' width='100%'/>
            </div>
            <div className='flex flex-col'>
               <Skeleton width='300px' />
               <Skeleton width='100px' />
               <div>
                 <Skeleton count={6} borderRadius='12px' height='50px'/>
               </div>
            </div>
          
        </div>

    </Skeleton.SkeletonThemeProvider>
</div>
);

export default CalendarSkeleton;
