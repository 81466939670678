import NavigationElements from "./pageElements/NavigationElements";
import DoctorResultsMotivo from "./pageElements/DoctorResultsMotivo";

export default function DoctorDetailsPage(props) {

    const {displayNavigation, onUpdateDisplayNavigation} = props;

    return <>
        <NavigationElements
            displayNavigation={displayNavigation}
            onUpdateDisplayNavigation={onUpdateDisplayNavigation}
        />
        <DoctorResultsMotivo />
    </>
}
