import usePersonalProfile from './hooks/usePersonalProfile';
import PersonalProfileForm from './personalProfileForm/PersonalProfileForm';

const PersonalProfile = ({ userType = 'patient' }) => {
  const {
    userId,
    userProfile,
    errorMessage,
    isLoading
  } = usePersonalProfile(userType);

  return <div className='space-y-6 sm:px-6 lg:px-0 lg:col-span-9 min-h-[calc(100vh_-_306px)]'>
           {
            !isLoading ? 
            <PersonalProfileForm
              userType={userType}
              userProfile={userProfile}
              userId={userId}
              errorMessage={errorMessage}
            />
            : null
           }
        </div>;
};

export default PersonalProfile;
