import NavigationElements from "./pageElements/NavigationElements";
import CalendarAppointmentDateSelectionElement from "./pageElements/CalendarAppointmentDateSelectionElement";
import FooterElement from "./pageElements/FooterElement";

export default function DoctorAppointmenDateSelectionPage(props) {

    const {displayNavigation, onUpdateDisplayNavigation} = props;

    return <div className={'m-0 h-full flex flex-col min-h-screen'}>
        <NavigationElements
            displayNavigation={displayNavigation}
            onUpdateDisplayNavigation={onUpdateDisplayNavigation}
        />
        <div className={'flex justify-center m-7'}>
            <CalendarAppointmentDateSelectionElement />
        </div>

        {/* Footer */}
        <div className={'mt-auto'}>
            <FooterElement />
        </div>
    </div>
}
