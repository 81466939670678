import React from 'react';

export default function ErrorBoundaryContent() {
    return (
        <div className="text-center h-[calc(100vh_-_306px)] bg-dl-screen-gray">
            <div className="pt-20">
                <svg
                    className="mx-auto h-12 w-12 text-red-600"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                >
                    <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z"
                    />
                </svg>

                <h3 className="mt-2 text-sm font-medium text-gray-900">Ocurrió un error</h3>
                <p className="mt-1 text-sm text-gray-500">Actualice el sitio e inténtelo de nuevo.</p>
            </div>
        </div>
    );
}
