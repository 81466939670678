/* eslint-disable no-unused-vars */
import { BriefcaseIcon, ChevronRightIcon, LocationMarkerIcon } from '@heroicons/react/outline';
import useSelectAppointmentMotive from '../../hooks/useSelectAppointmentMotive';

export default function DoctorResultsTabsElement() {
    const { docData, onMotiveSelected } = useSelectAppointmentMotive();

    return (
        <div className="min-h-[90vh]">
            {/* Page heading */}
            <header className="bg-dl-primary-900/[.1] py-8">
                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 xl:flex xl:items-center xl:justify-between">
                    <div className="flex-1 min-w-0">
                        <nav className="flex" aria-label="Breadcrumb">
                            <ol role="list" className="flex items-center space-x-4">
                                <li>
                                    <div>
                                        <div className="text-sm font-medium text-gray-500 hover:text-gray-700">
                                            {docData.speciality}
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div className="flex items-center">
                                        <ChevronRightIcon
                                            className="flex-shrink-0 h-5 w-5 text-gray-400"
                                            aria-hidden="true"
                                        />
                                        <div className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700">
                                            {docData.fullName}
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div className="flex items-center">
                                        <ChevronRightIcon
                                            className="flex-shrink-0 h-5 w-5 text-gray-400"
                                            aria-hidden="true"
                                        />
                                        <div className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700">
                                            Motivo de consulta
                                        </div>
                                    </div>
                                </li>
                            </ol>
                        </nav>
                        <h1 className="mt-2 text-2xl font-bold leading-7 text-dl-accent sm:text-3xl sm:truncate">
                            Motivo de consulta
                        </h1>

                        {/* Profile */}
                        <div
                            role="list"
                            className="mt-5 border-t border-gray-200 divide-y divide-gray-200 sm:mt-0 sm:border-t-0"
                        >
                            <div>
                                <div className="flex items-center py-5 px-4 sm:py-6 sm:px-0">
                                    <div className="min-w-0 flex-1 flex items-center">
                                        <div className="flex-shrink-0">
                                            <img
                                                className="h-40 w-40 rounded-full group-hover:opacity-75 border-2 border-dl-primary-500"
                                                src={docData.personalImage}
                                                alt=""
                                            />
                                        </div>
                                        <div className="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4">
                                            <div>
                                                <p className="text-lg font-medium text-dl-accent truncate">
                                                    {docData.fullName}
                                                </p>
                                                <p className="mt-2 flex items-center text-sm text-gray-500">
                                                    <BriefcaseIcon
                                                        className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                                                        aria-hidden="true"
                                                    />
                                                    <span className="truncate">{docData.speciality}</span>
                                                </p>
                                                <p className="mt-2 flex items-center text-sm text-gray-500">
                                                    <LocationMarkerIcon
                                                        className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                                                        aria-hidden="true"
                                                    />
                                                    <span className="">{docData.address}</span>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>

            <main className="pt-8 pb-16">
                <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
                    {/* Body tabs */}

                    <div className="pt-8 px-4 sm:px-0">
                        {/* Stacked list */}

                        {/* Description list*/}
                        <section aria-labelledby="applicant-information-title">
                            <div className="bg-white shadow sm:rounded-lg">
                                <div className="px-4 py-5 sm:px-6">
                                    <h2
                                        id="applicant-information-title"
                                        className="text-lg leading-6 font-medium text-dl-secondary"
                                    >
                                        Elige motivo de consulta
                                    </h2>
                                </div>
                                <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                                    <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                                        <div className="sm:col-span-2">
                                            <ul
                                                role="list"
                                                className="mt-5 border-t border-gray-200 divide-y divide-gray-200 sm:mt-0 sm:border-t-0"
                                            >
                                                {docData.motives?.map((item) => (
                                                    <li key={item.id}>
                                                        <div
                                                            role="button"
                                                            onClick={() => onMotiveSelected(item)}
                                                            className="group block"
                                                        >
                                                            <div className="flex items-center py-5 px-4 sm:py-6 sm:px-0">
                                                                <div className="min-w-0 flex-1 flex items-center">
                                                                    <div className="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4">
                                                                        <div>
                                                                            <p className="text-sm font-medium text-dl-accent truncate">
                                                                                {item.motiveName}
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div>
                                                                    <ChevronRightIcon
                                                                        className="h-5 w-5 text-gray-400 group-hover:text-gray-700"
                                                                        aria-hidden="true"
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    </dl>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </main>
        </div>
    );
}
