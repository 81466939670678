import { CreditCardIcon, KeyIcon, UserCircleIcon, UserGroupIcon, ViewGridAddIcon } from '@heroicons/react/outline';
import NavigationElements from "./pageElements/NavigationElements";
import DoctorUserProfileElement from "./pageElements/DoctorUserProfileElement";

const navigation = [
    { name: 'Account', href: '#', icon: UserCircleIcon, current: true },
    { name: 'Password', href: '#', icon: KeyIcon, current: false },
    { name: 'Plan & Billing', href: '#', icon: CreditCardIcon, current: false },
    { name: 'Team', href: '#', icon: UserGroupIcon, current: false },
    { name: 'Integrations', href: '#', icon: ViewGridAddIcon, current: false },
]

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function DoctorProfilePage() {
    return (
        <NavigationElements selectNavigation={'auth'}>
            <DoctorUserProfileElement/>
        </NavigationElements>
    )
}
