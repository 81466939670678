import { useForm } from 'react-hook-form';
import { useEffect, useState } from 'react';
import createMotives from '../api/createMotives';
import updateMotives from '../api/updateMotives';

export default function useDoctorMotives(queryAllDoctorMotivess) {
    const [isLoading, setIsLoading] = useState(true);
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [errorBody, setErrorBody] = useState('');
    const [successBody, setSuccessBody] = useState('');
    const [defaultValues, setDefaultValues] = useState({});
    const [selectedMotives, setSelectedMotives] = useState(null);

    const {
        register,
        handleSubmit,
        formState: { errors, isDirty },
        watch,
        reset,
        setValue
    } = useForm(defaultValues);

    useEffect(() => {
        setIsLoading(true);

        if (selectedMotives) {
            setDefaultValues(selectedMotives);
            reset(selectedMotives);

            setValue('status', selectedMotives.status ? 'true' : 'false', { shouldValidate: true });
        }

        setIsLoading(false);
    }, [reset, selectedMotives, setValue]);

    const resetState = () => {
        setShowSuccessModal(false);
        setShowErrorModal(false);
        setErrorBody('');
        setSuccessBody('');
        setDefaultValues({});
        setSelectedMotives(null);
        reset({ motivesName: '', motivesDetail: '' });
        setValue('status', '', { shouldValidate: true });
    };

    const _createErrorMessage = (errorMessage) => {
        setShowErrorModal(true);
        setErrorBody(errorMessage);
    };

    const closeErrorMessage = () => {
        setShowErrorModal(false);
        setErrorBody('');
    };

    const _createSuccessMessage = (errorMessage) => {
        setShowSuccessModal(true);
        setSuccessBody(errorMessage);
    };

    const saveMotives = (data) => {
        if (!data) {
            _createErrorMessage('No se pudo obtener información válida para guardar. Inténtelo de nuevo.');
            return;
        }

        if (JSON.stringify(defaultValues) === '{}') {
            executeCreateMotives(data);
        } else {
            const adjustedData = { ...data, idMotive: data.id, status: data.status === 'true' };
            doUpdateMotives(adjustedData);
        }
    };

    const executeCreateMotives = async (newMotives) => {
        setIsLoading(true);
        const createNewMotives = await createMotives(newMotives, _createErrorMessage);

        if (createNewMotives) {
            _createSuccessMessage('¡Se ha guardado el registro con éxito!');
        }

        setIsLoading(false);
    };

    const doUpdateMotives = async (updatedMotivesObject) => {
        setIsLoading(true);
        const executeUpdatedMotives = await updateMotives(updatedMotivesObject, _createErrorMessage);

        if (executeUpdatedMotives) {
            _createSuccessMessage('¡Se ha actualizado el registro con éxito!');
        }

        setIsLoading(false);
    };

    const onCloseSuccessMessage = () => {
        resetState();
        queryAllDoctorMotivess();
    };

    return {
        register,
        handleSubmit,
        errors,
        watch,
        reset,
        setValue,
        showErrorModal,
        setShowErrorModal,
        closeErrorMessage,
        showSuccessModal,
        setShowSuccessModal,
        successBody,
        errorBody,
        setErrorBody,
        setSelectedMotives,
        onCloseSuccessMessage,
        saveMotives,
        isEditing: JSON.stringify(defaultValues) !== '{}',
        defaultValues,
        isLoading,
        resetState,
        isDirty
    };
}
