import { useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import DatePicker from 'react-datepicker'

import NavigationElements from '../components/pageElements/NavigationElements';
import FooterElement from '../components/pageElements/FooterElement';
import FormComboBox from '../common/components/formElements/FormComboBox';
import { personalDocuments, formattedCountryData } from '../common/utils/formattedData';
import imgLogin from './../images/dl-login.png'
import { useForm, Controller } from 'react-hook-form';
import SuccessModal from '../common/components/customSuccessModal/CustomSuccessModal';
import ErrorModal from '../common/components/customRedAlert/CustomRedAlert';
import InputMask from 'comigo-tech-react-input-mask';
import useSignUp from './useSignUp';

const date = new Date();
const MIN_AGE = 18;
const MAX_DATE = new Date(date.setFullYear(date.getFullYear() - MIN_AGE)).toJSON().slice(0, 10);
const defaultNationalityOpt = { 
    id: 'default',
    label: 'Selecciona nacionalidad',
    value: 'Selecciona nacionalidad',
    disabled: true
};

export default function SignUpPage(props) {
    const { 
        displayNavigation,
        onUpdateDisplayNavigation,
        userType = 'patient' 
    } = props;
    const navigate = useNavigate();
    const { 
        register,
        handleSubmit,
        formState: { errors },
        watch,
        control
    } = useForm();
    const password = useRef({});

    const { 
        onSignUp,
        selectedDocument,
        setSelectedDocument,
        showSuccessModal,
        showErrorModal,
        errorBody,
        onCloseErrorModal,
        onCloseSuccessModal,
        documentTypes
    } = useSignUp(userType); 
   

    password.current = watch('password', '');

    const validatePasswordConfirmation =
      (value) => value === password.current;

    const validateAgreement =
      (value) => value === 'accepted';


    const personalDocumentValidation = (value) => {
        if (selectedDocument.label === 'DUI') {
            return /^\d{8}-\d{1}$/i.test(value);
        }

        return true;
    };

    return (
        <NavigationElements
            displayNavigation={displayNavigation}
            onUpdateDisplayNavigation={onUpdateDisplayNavigation}
        >
            <div className='bg-dl-screen-gray py-12 px-4 sm:px-6 lg:px-20 xl:px-24'>
                <div className='w-full pb-10 text-center'>
                    {/* <Link to={'/'}>
                    <img
                        className='w-72 mx-auto'
                        src={logo}
                        alt='Workflow'
                    />
                </Link> */}
                    <h2 className='mt-6 text-3xl font-extrabold text-dl-secondary'>Bienvenido</h2>
                    <p className='mt-2 text-sm text-dl-secondary'>

                        <span className='font-medium text-dl-secondary'>
                            Ingresar los siguientes datos para continuar
                        </span>
                    </p>
                </div>

                <div className='min-h-full grid grid-flow-col gap-4'>


                    <div className='col-span-12 md:col-span-8'>

                        <form className=' space-y-6' onSubmit={
                            handleSubmit(onSignUp)
                        }>
                            <div className='shadow sm:rounded-md sm:overflow-hidden'>
                                <div className='bg-white py-6 px-4 space-y-6 sm:p-6'>
                                    <div>
                                        <h3 className='text-lg leading-6 font-medium text-dl-secondary'>Datos generales</h3>

                                    </div>

                                    <div className='grid grid-cols-6 gap-6'>
                                        <div className='col-span-6 sm:col-span-3'>
                                            <label htmlFor='first-name' className='block text-sm font-medium text-dl-accent'>
                                                Nombres
                                            </label>
                                            <input
                                                type='text'
                                                name='first-name'
                                                id='first-name'
                                                autoComplete='given-name'
                                                className='mt-1 block w-full border border-gray-300 rounded-full shadow-sm py-2 px-3 focus:outline-none focus:ring-dl-primary-700 focus:border-dl-primary-700 sm:text-sm'
                                                {...register('firstName', { required: true })}
                                            />
                                            { errors.firstName
                                              ? <p className="text-red-700 font-light">
                                                  Este campo no puede ser vacío
                                                </p>
                                              : null
                                            }
                                        </div>

                                        <div className='col-span-6 sm:col-span-3'>
                                            <label htmlFor='last-name' className='block text-sm font-medium text-dl-accent'>
                                                Apellidos
                                            </label>
                                            <input
                                                type='text'
                                                name='last-name'
                                                id='last-name'
                                                autoComplete='family-name'
                                                className='mt-1 block w-full border border-gray-300 rounded-full shadow-sm py-2 px-3 focus:outline-none focus:ring-dl-primary-700 focus:border-dl-primary-700 sm:text-sm'
                                                {...register('lastName', { required: true })}
                                            />
                                             { errors.lastName
                                              ? <p className="text-red-700 font-light">
                                                  Este campo no puede ser vacío
                                                </p>
                                              : null
                                            }
                                        </div>

                                        <div className='col-span-6 '>
                                            <label htmlFor='phone' className='block text-sm font-medium text-dl-accent'>
                                                Teléfono
                                            </label>
                                            <input
                                                type='text'
                                                name='phone'
                                                id='phone'
                                                autoComplete='family-name'
                                                className='mt-1 block w-full border border-gray-300 rounded-full shadow-sm py-2 px-3 focus:outline-none focus:ring-dl-primary-700 focus:border-dl-primary-700 sm:text-sm'
                                                {...register('phone', { required: true })}
                                            />
                                              { errors.phone
                                                ? <p className="text-red-700 font-light">
                                                    Este campo no puede ser vacío
                                                    </p>
                                                : null
                                              }
                                        </div>


                                        <div className='col-span-6'>
                                            <label htmlFor='address' className='block text-sm font-medium text-dl-accent'>
                                                Dirección
                                            </label>
                                            <div className='mt-1'>
                                                <textarea
                                                    id='address'
                                                    name='address'
                                                    rows={3}
                                                    className='shadow-sm focus:ring-dl-primary-700 focus:border-dl-primary-700 mt-1 block w-full sm:text-sm border border-gray-300 rounded-2xl'
                                                    defaultValue={''}
                                                    {...register('address', { required: true })}
                                                />
                                                 { errors.address
                                                    ? <p className="text-red-700 font-light">
                                                        Este campo no puede ser vacío
                                                        </p>
                                                    : null
                                                 }
                                            </div>
                                        </div>


                                    </div>
                                </div>
                            </div>

                            <div className='shadow sm:rounded-md sm:overflow-hidden'>
                                <div className='bg-white py-6 px-4 space-y-6 sm:p-6'>
                                    <div>
                                        <h3 
                                          className='text-lg leading-6 font-medium text-dl-secondary'
                                        >
                                          Documentación
                                        </h3>
                                    </div>

                                    <div className='grid grid-cols-6 gap-6'>
                                       { userType == 'doctor' ? (<div className='col-span-6'>
                                           <Controller
                                             control={control}
                                             name='nationality'
                                             defaultValue={defaultNationalityOpt}
                                             rules={{
                                                validate: (value) => value.id !== 'default'
                                             }}
                                             render={({field}) => (
                                                <FormComboBox
                                                label='Nacionalidad'
                                                data={
                                                   [
                                                      defaultNationalityOpt,
                                                      ...formattedCountryData
                                                   ]
                                                 }
                                                value={field.value}
                                                onChange={(value) => field.onChange(value)}
                                               />
                                             )}
                                           />

                                            { errors.nationality?.type === 'validate'
                                                ? <p className="text-red-700 font-light">
                                                    Seleccione una nacionalidad
                                                  </p>
                                                : null
                                            }
                                        </div>) : null }
                                        <div className='col-span-6'>
                                            <label 
                                              htmlFor='personal-document'
                                              className='block text-sm font-medium text-dl-accent'
                                            >
                                                Documento de identificación
                                            </label>
                                            <div className='flex flex-row gap-1.5'>
                                            {
                                                    documentTypes?.length > 0 && selectedDocument !== null ? 
                                                    (<>
                                                        <InputMask
                                                            type='text'
                                                            name='personal-document'
                                                            id='personal-document'
                                                            autoComplete='family-name'
                                                            className='mt-1 block w-3/4 border border-gray-300 rounded-full shadow-sm py-2 px-3 focus:outline-none focus:ring-dl-primary-700 focus:border-dl-primary-700 sm:text-sm'
                                                            mask={selectedDocument.label == 'DUI' ? '99999999-9' : ''}
                                                            {...register('personalDocument', {
                                                                required: true,
                                                                validate: personalDocumentValidation
                                                            })}
                                                        />
                                                        <FormComboBox
                                                            data={documentTypes.map((doc) => ({
                                                                ...doc,
                                                                label: personalDocuments[doc.documentName] ?? doc.documentName,
                                                                value: personalDocuments[doc.documentName] ?? doc.documentName

                                                            }))}
                                                            value={selectedDocument}
                                                            onChange={(e) => setSelectedDocument(e)}
                                                            className='w-1/4'
                                                        />
                                                    </>) : null
                                                }
                                            </div>
                                            { errors.personalDocument?.type == 'required'
                                                    ? <p className="text-red-700 font-light text-left">
                                                        Este campo no puede ser vacío
                                                        </p>
                                                    : null
                                            }
                                            { errors.personalDocument?.type == 'validate'
                                                    ? <p className="text-red-700 font-light text-left">
                                                        Formato ingresado no es válido
                                                        </p>
                                                    : null
                                            }

                                           
                                            <p className='mt-2 text-sm text-right text-gray-500'>
                                                DUI / Pasaporte
                                            </p>
                                        
                                        </div>

                                        <div className='col-span-6 '>
                                            <label htmlFor='birthdate' className='block text-sm font-medium text-dl-accent'>
                                                Fecha de nacimiento
                                            </label>
                                          <Controller
                                            control={control}
                                            name='birthDate'
                                            rules={{
                                              required: true
                                            }}
                                            render={({field}) => (
                                                <DatePicker
                                                    selected={field.value}
                                                    dateFormat='dd/MM/yyyy'
                                                    onChange={(date) => field.onChange(date)}
                                                    maxDate={new Date(MAX_DATE)}
                                                    peekNextMonth
                                                    showMonthDropdown
                                                    showYearDropdown
                                                    dropdownMode='select'
                                                    className='mt-1 block w-full border border-gray-300 rounded-full shadow-sm py-2 px-3 focus:outline-none focus:ring-dl-primary-700 focus:border-dl-primary-700 sm:text-sm'    
                                                />
                                            )}
                                          />
                                           { errors.birthDate
                                                ? <p className="text-red-700 font-light text-left">
                                                    Este campo no puede ser vacío
                                                    </p>
                                                : null
                                            }
                                            <p className='mt-2 text-sm text-right text-gray-500'>
                                                Dia / Mes / Año
                                            </p>
                                        </div>

                                    </div>
                                </div>
                            </div>

                            <div className='shadow sm:rounded-mdd sm:overflow-hidden'>
                                <div className='bg-white py-6 px-4 space-y-6 sm:p-6'>
                                    <div>
                                        <h3 className='text-lg leading-6 font-medium text-dl-secondary'>Cuenta</h3>

                                    </div>

                                    <div className='grid grid-cols-6 gap-6'>
                                        <div className='col-span-6 '>
                                            <label htmlFor='email-address' className='block text-sm font-medium text-dl-accent'>
                                               Correo electrónico
                                            </label>
                                            <input
                                                type='email'
                                                name='email-address'
                                                id='email-address'
                                                autoComplete='email'
                                                className='mt-1 block w-full border border-gray-300 rounded-full shadow-sm py-2 px-3 focus:outline-none focus:ring-dl-primary-700 focus:border-dl-primary-700 sm:text-sm'
                                                {...register('email', { required: true })}
                                            />
                                               { errors.email
                                                    ? <p className="text-red-700 font-light">
                                                        Este campo no puede ser vacío
                                                        </p>
                                                    : null
                                               }
                                        </div>

                                        <div className='col-span-6 sm:col-span-3'>
                                            <label htmlFor='password' className='block text-sm font-medium text-dl-accent'>
                                                Contraseña
                                            </label>
                                            <input
                                                type='password'
                                                name='password'
                                                id='password'
                                                autoComplete='current-password'
                                                className='mt-1 block w-full border border-gray-300 rounded-full shadow-sm py-2 px-3 focus:outline-none focus:ring-dl-primary-700 focus:border-dl-primary-700 sm:text-sm'
                                                {...register('password', { required: true })}
                                            />
                                               { errors.password
                                                    ? <p className="text-red-700 font-light">
                                                        Este campo no puede ser vacío
                                                        </p>
                                                    : null
                                               }
                                        </div>

                                        <div className='col-span-6 sm:col-span-3'>
                                            <label htmlFor='password-conf' className='block text-sm font-medium text-dl-accent'>
                                                Confirmar contraseña
                                            </label>
                                            <input
                                                type='password'
                                                name='password-conf'
                                                id='password-conf'
                                                autoComplete='current-password'
                                                className='mt-1 block w-full border border-gray-300 rounded-full shadow-sm py-2 px-3 focus:outline-none focus:ring-dl-primary-700 focus:border-dl-primary-700 sm:text-sm'
                                                {...register('passwordConf', { 
                                                    required: true,
                                                    validate: validatePasswordConfirmation 
                                                })}
                                            />
                                            { errors.passwordConf?.type === 'required' 
                                                    ? <p className="text-red-700 font-light">
                                                        Confirme contraseña 
                                                      </p>
                                                    : null
                                            }

                                            {
                                              errors.passwordConf?.type === 'validate'
                                               ? <p className="text-red-700 font-light">
                                                Las contraseñas deben coincidir
                                               </p>
                                              : null
                                            }

                                        </div>

                                        <fieldset className='col-span-6'>
                                            <div className='mt-4 space-y-4'>
                                                <div className='flex items-start'>
                                                    <div className='h-5 flex items-center'>
                                                        <input
                                                            id='comments'
                                                            name='comments'
                                                            type='checkbox'
                                                            value='accepted'
                                                            className='focus:ring-dl-green h-4 w-4 text-dl-green border-gray-300 rounded'
                                                            {...register('agreement', { validate: validateAgreement })}
                                                        />
                                                    </div>
                                                    <div className='ml-3 text-sm'>
                                                        <label htmlFor='comments' className='font-medium text-dl-accent'>
                                                            Acepto &nbsp;
                                                            <a href='' className='text-dl-primary-900 hover:text-dl-primary-700'>
                                                                Terminos y condiciones de DoctoLink
                                                            </a>
                                                        </label>
                                                    </div>
                                                </div>
                                                { errors.agreement
                                                    ? <p className="text-red-700 font-light">
                                                        Acepte terminos y condiciones
                                                      </p>
                                                    : null
                                               }
                                            </div>
                                        </fieldset>

                                    </div>
                                </div>
                                <div className='px-4 py-3 bg-gray-50 text-right sm:px-6'>
                                    <button
                                        type='submit'
                                        className='bg-dl-primary-900 border border-transparent rounded-full shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-dl-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-dl-primary-700'
                                    >
                                        Registrar
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>

                    <div className='hidden md:block md:col-span-4'>
                        <img
                            className='inset-0 w-72 mx-auto'
                            src={imgLogin}
                            alt=''
                        />
                        <img
                            className='inset-0 w-72 mx-auto'
                            src={imgLogin}
                            alt=''
                        />
                        <img
                            className='inset-0 w-72 mx-auto'
                            src={imgLogin}
                            alt=''
                        />
                    </div>
                </div>
            </div>

         <SuccessModal
           title='Cuenta creada exitosamente'
           bodyText='Se ha enviado un correo electrónico para confirmar su cuenta'
           buttonText='Ir al login'
           buttonAction={() => navigate(`/sign-in${userType === 'doctor' ? '-doctor' :  ''}`)}
           open={showSuccessModal}
           onClose={onCloseSuccessModal}
        />

        <ErrorModal
           title='Error al crear cuenta'
           bodyText={errorBody}
           mainActionText='Cerrar'
           onMainAction={onCloseErrorModal}
           open={showErrorModal} 
           onClose={onCloseErrorModal}
        />
        {/* Footer */}
        <FooterElement />

        </NavigationElements>

    )
}
