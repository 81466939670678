import NavigationElements from "./pageElements/NavigationElements";
import DoctorReviewElement from "./pageElements/DoctorReviewElement";

export default function DoctorsReviewPage(props) {

    const {displayNavigation, onUpdateDisplayNavigation} = props;

    return <>
        <NavigationElements
            displayNavigation={displayNavigation}
            onUpdateDisplayNavigation={onUpdateDisplayNavigation}
        />
        <DoctorReviewElement />
    </>
}
