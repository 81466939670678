import MainHeaderElement from "./MainHeaderElement";
import Navigation from "./UserNavigationElement";


export default function NavigationElement(props) {

    const { selectNavigation, displayNavigation, onUpdateDisplayNavigation } = props;

    if(!selectNavigation) {
        return  <>
            {displayNavigation && <Navigation updateNavigationFunction={onUpdateDisplayNavigation} />}
            {props.children}
        </>
    }

    return  <>
        {selectNavigation === 'regular' && <MainHeaderElement updateNavigationFunction={onUpdateDisplayNavigation} />}
        {selectNavigation === 'auth' && <Navigation updateNavigationFunction={onUpdateDisplayNavigation} />}
        {props.children}
    </>


}
