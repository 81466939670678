/* eslint-disable no-unused-vars */
import { useState } from 'react'
import {
    ChevronRightIcon,
    MailIcon,
} from '@heroicons/react/solid'
import {Link} from "react-router-dom";
import useDoctorResults from '../../hooks/useDoctorResults';
import ItemCard from '../itemCard/ItemCard';
import { BriefcaseIcon, LocationMarkerIcon } from '@heroicons/react/outline';




function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function DoctorResultsTabsElement() {

    const {
     // isLoading,
      doctorData,
      tabs,
      onTabSelected
    } = useDoctorResults();

    return (
        <>
            <div className="min-h-full">
                <header className="bg-dl-primary-900/[.1] py-8">
                    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 xl:flex xl:items-center xl:justify-between">
                        <div className="flex-1 min-w-0">
                            <nav className="flex" aria-label="Breadcrumb">
                                <ol role="list" className="flex items-center space-x-4">
                                    <li>
                                        <div>
                                            <div  className="text-sm font-medium text-gray-500 hover:text-gray-700">
                                                {doctorData.speciality}
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="flex items-center">
                                            <ChevronRightIcon className="flex-shrink-0 h-5 w-5 text-gray-400" aria-hidden="true"/>
                                            <div className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700">
                                               { doctorData.fullName }
                                            </div>
                                        </div>
                                    </li>
                                </ol>
                            </nav>
                            <h1 className="mt-2 text-2xl font-bold leading-7 text-dl-accent sm:text-3xl sm:truncate">
                                Perfil doctor
                            </h1>

                            {/* Stacked list */}
                            <ul role="list" className="mt-5 border-t border-gray-200 divide-y divide-gray-200 sm:mt-0 sm:border-t-0">
                                    <li>
                                        <div className="group block">
                                            <div className="flex items-center py-5 px-4 sm:py-6 sm:px-0">
                                                <div className="min-w-0 flex-1 flex items-center">
                                                    <div className="flex-shrink-0">
                                                        <img className="h-40 w-40 rounded-full group-hover:opacity-75 border-2 border-dl-primary-500"
                                                            src={
                                                                doctorData.personalImage
                                                            }
                                                            alt=""/>
                                                    </div>
                                                    <div className="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4">
                                                        <div>
                                                            <p className="text-lg font-medium text-dl-accent truncate">
                                                                {
                                                                doctorData.fullName
                                                            }</p>
                                                            <p className="mt-2 flex items-center text-sm text-gray-500">
                                                                <BriefcaseIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true"/>
                                                                <span className="truncate">
                                                                    {
                                                                    doctorData.speciality
                                                                }</span>
                                                            </p>
                                                            <p className="mt-2 flex items-center text-sm text-gray-500">
                                                                <LocationMarkerIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true"/>
                                                                <span className="">
                                                                    {
                                                                    doctorData.address
                                                                }</span>
                                                            </p>
                                                        </div>
                                                        {
                                                            doctorData.id && doctorData.fullName ? (<Link to={`/doctor-results/${doctorData.id}/${doctorData.fullName?.replace(' ', '-')}/appointment-motive`} className=" md:block mx-auto mt-5">
                                                            <div>
                                                                <span className="sm:block">
                                                                    <button type="button" className="inline-flex items-center px-4 py-2 border border-transparent rounded-full shadow-sm text-base font-medium text-white bg-dl-primary-900 hover:bg-dl-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-dl-primary-700 focus:ring-dl-primary-700">
                                                                        Reservar una cita
                                                                    </button>
                                                                </span>
                                                            </div>
                                                        </Link>) : null
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                </ul>

                            {/* <div className="mt-1 flex flex-col sm:flex-row sm:flex-wrap sm:mt-0 sm:space-x-8">
                                <div className="mt-2 flex items-center text-sm text-gray-500">
                                    <BriefcaseIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                                    Full-time
                                </div>
                                <div className="mt-2 flex items-center text-sm text-gray-500">
                                    <LocationMarkerIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                                    Remote
                                </div>
                                <div className="mt-2 flex items-center text-sm text-gray-500">
                                    <CurrencyDollarIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                                    $120k &ndash; $140k
                                </div>
                                <div className="mt-2 flex items-center text-sm text-gray-500">
                                    <CalendarIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                                    Closing on January 9, 2020
                                </div>
                            </div> */} 
                        </div>

                    </div>
                </header>

                <main className="pt-8 pb-16 min-h-[calc(100vh_-_306px)]">
                    <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
                        <div className="px-4 sm:px-0">
                            <h2 className="text-lg font-medium text-dl-accent">
                                Datos generales
                            </h2>

                            {/* Tabs */}
                            <div className="sm:hidden">
                                <label htmlFor="tabs" className="sr-only">
                                    Select a tab
                                </label>
                                <select
                                   id="tabs"
                                   name="tabs"
                                   className="mt-4 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-purple-500 focus:border-purple-500 sm:text-sm rounded-md"
                                   defaultValue={
                                        tabs.find((tab) => tab.current).name
                                   }
                                   onChange={(e) => onTabSelected(e.target.value)}
                                >
                                    {
                                    tabs.map((tab) => (
                                        <option
                                          key={
                                            tab.name
                                          }
                                          value={tab.id}
                                        >
                                            {
                                            tab.name
                                        }</option>
                                    ))
                                } </select>
                            </div>
                            <div className="hidden sm:block">
                                <div className="border-b border-gray-200">
                                    <nav className="mt-2 -mb-px flex space-x-8" aria-label="Tabs">
                                        {
                                        tabs.map((tab, index) => (
                                            <div
                                                role='button'
                                                tabIndex={index}
                                                onClick={() => onTabSelected(tab.id)}
                                                key={
                                                    tab.name
                                                }
                                                className={
                                                    classNames('cursor-pointer', tab.current ? 'border-dl-primary-500 text-dl-primary-900' : 'border-transparent text-dl-accent hover:text-gray-600 hover:border-gray-200', 'whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm')
                                            }>
                                                {tab.name}
                                         </div>
                                        ))
                                    } </nav>
                                </div>
                            </div>
                        </div>
                        <div className='flex flex-col gap-6 mt-6'>
                            {
                                tabs.map((tab) => tab.current ?
                                    tab.sections.map((section) => <ItemCard  section={section} key={section.name} />)
                                : null)
                            }
                        </div>
                    </div>
                </main>
            </div>
        </>
    )
}
