import WhiteCardContainer from '../../../../common/components/containers/forms/WhiteCardContainer';
import SchedulesList from './components/schedulesList/SchedulesList';
import FormFieldsContainer from '../../../../common/components/containers/forms/FormFieldsContainer';
import ProfileFormHeader from '../../ProfileFormHeader/ProfileFormHeader';
import HalfRow from '../../../../common/components/formElements/HalfRow';
import useDoctorSchedules from './hooks/useDoctorSchedules';
import FormButton from '../../../../common/components/formElements/FormButton';
import FormFieldsFooter from '../../../../common/components/containers/forms/FormFieldsFooter';
import FormSelectDrop from '../../../../common/components/formElements/FormSelectDrop';
import CustomRedAlert from '../../../../common/components/customRedAlert/CustomRedAlert';
import CustomSuccessModal from '../../../../common/components/customSuccessModal/CustomSuccessModal';
import FormResetButton from '../../../../common/components/formElements/FormResetButton';
import FormDatePicker from '../../../../common/components/formElements/FormDatePicker';
import { ClockIcon } from '@heroicons/react/outline';
import { Controller } from 'react-hook-form';
import { setHours, setMinutes } from 'date-fns';

const statusOptions = [
    { id: true, label: 'Activo' },
    { id: false, label: 'Inactivo' }
];

const dayOptions = [
    { id: 'Lunes', label: 'Lunes' },
    { id: 'Martes', label: 'Martes' },
    { id: 'Miercoles', label: 'Miércoles' },
    { id: 'Jueves', label: 'Jueves' },
    { id: 'Viernes', label: 'Viernes' },
    { id: 'Sabado', label: 'Sábado' },
    { id: 'Domingo', label: 'Domingo' }
];

export default function DoctorSchedules(props) {
    const { schedules, queryAllDoctorSchedules } = props;

    const {
        register,
        errors,
        control,
        handleSubmit,
        saveSchedules,
        isEditing,
        defaultValues,
        showErrorModal,
        errorBody,
        closeErrorMessage,
        showSuccessModal,
        successBody,
        onCloseSuccessMessage,
        setSelectedSchedules,
        resetState,
        isDirty
    } = useDoctorSchedules(queryAllDoctorSchedules);

    return (
        <>
            <form action="#" method="POST" onSubmit={handleSubmit(saveSchedules)}>
                <WhiteCardContainer>
                    <FormFieldsContainer>
                        <ProfileFormHeader
                            title={!isEditing ? 'Nuevo Horario' : 'Actualizar Horario'}
                            initialText={`Ingresa Horarios generales en los que atiendes pacientes. 
                                Estos NO son los espacios de consulta, solamente es de carácter informativo.`}
                        />
                        <div className="grid grid-cols-6 gap-6">
                            <HalfRow>
                                <FormSelectDrop
                                    label={'Dia'}
                                    identifier={'dayName'}
                                    options={dayOptions}
                                    defaultValue={defaultValues.dayName}
                                    helperText={'Requerido'}
                                    registration={register('dayName', { required: true })}
                                    errorMessage={errors.dayName ? 'Este campo es requerido' : null}
                                />
                            </HalfRow>
                            <HalfRow></HalfRow>
                            <HalfRow>
                                <Controller
                                    control={control}
                                    name="openHours1"
                                    rules={{ required: true }}
                                    render={({ field }) => {
                                        return (
                                            <FormDatePicker
                                                identifier="openHours1"
                                                label="Seleccionar hora de inicio"
                                                errorMessage={errors.openHours1 ? 'Este campo es requerido' : null}
                                                helperText={'Requerido'}
                                                iconComponent={
                                                    <ClockIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                                }
                                                datePickerProps={{
                                                    onKeyDown: (e) => {
                                                        e.preventDefault();
                                                    },
                                                    selected: field.value,
                                                    dateFormat: 'HH:mm:ss',
                                                    onChange: (date) => field.onChange(date),
                                                    showTimeSelect: true,
                                                    showTimeSelectOnly: true,
                                                    timeIntervals: 15,
                                                    timeCaption: 'Hora',
                                                    dropdownMode: 'select',
                                                    minTime: setHours(setMinutes(new Date(), 0), 7),
                                                    maxTime: setHours(setMinutes(new Date(), 0), 20)
                                                }}
                                            />
                                        );
                                    }}
                                />
                            </HalfRow>
                            <HalfRow>
                                <Controller
                                    control={control}
                                    name="openHours2"
                                    rules={{ required: true }}
                                    render={({ field }) => (
                                        <FormDatePicker
                                            identifier="openHours2"
                                            label="Seleccionar hora final"
                                            errorMessage={errors.openHours2 ? 'Este campo es requerido' : null}
                                            helperText={'Requerido'}
                                            iconComponent={
                                                <ClockIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                            }
                                            datePickerProps={{
                                                onKeyDown: (e) => {
                                                    e.preventDefault();
                                                },
                                                selected: field.value,
                                                dateFormat: 'HH:mm:ss',
                                                onChange: (date) => field.onChange(date),
                                                showTimeSelect: true,
                                                showTimeSelectOnly: true,
                                                timeIntervals: 15,
                                                timeCaption: 'Hora',
                                                dropdownMode: 'select',
                                                minTime: setHours(setMinutes(new Date(), 0), 7),
                                                maxTime: setHours(setMinutes(new Date(), 0), 20)
                                            }}
                                        />
                                    )}
                                />
                            </HalfRow>
                            {isEditing && (
                                <HalfRow>
                                    <FormSelectDrop
                                        label={'Estado'}
                                        identifier={'status'}
                                        options={statusOptions}
                                        defaultValue={defaultValues.status}
                                        helperText={'Requerido'}
                                        registration={register('status', { required: true })}
                                        errorMessage={errors.status ? 'Este campo es requerido' : null}
                                    />
                                </HalfRow>
                            )}
                            <div className="h-40"></div>
                        </div>
                    </FormFieldsContainer>
                    <FormFieldsFooter>
                        <FormResetButton onClick={resetState} label={'Cancelar'} />
                        <FormButton
                            label={isEditing ? 'Actualizar' : 'Guardar'}
                            type={'submit'}
                            disabled={isEditing && !isDirty}
                        />
                    </FormFieldsFooter>
                </WhiteCardContainer>
                {showErrorModal && (
                    <CustomRedAlert
                        open={true}
                        title={'Ocurrió un error.'}
                        bodyText={errorBody}
                        onClose={closeErrorMessage}
                    />
                )}

                {showSuccessModal && (
                    <CustomSuccessModal
                        title={isEditing ? 'Registro actualizado con éxito' : 'Nuevo registro guardado con éxito'}
                        bodyText={successBody}
                        buttonText={'Aceptar'}
                        buttonAction={onCloseSuccessMessage}
                        open={showSuccessModal}
                        onClose={onCloseSuccessMessage}
                    />
                )}
            </form>
            <div className="ml-1">
                <div className="ml-5">
                    <ProfileFormHeader title={'Horarios existentes'} />
                </div>
                {schedules.length === 0 ? (
                    <p className="ml-4 mt-4">No hay horarios para mostrar</p>
                ) : (
                    <SchedulesList items={schedules} onClick={setSelectedSchedules} />
                )}
            </div>
        </>
    );
}
