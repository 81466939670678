// import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import DoctorProfileNavigation from './components/doctorProfileNavigation/DoctorProfileNavigation';
import DoctorOwnProfile from './components/doctorOwnProfile/DoctorOwnProfile';
import PersonalInfo from '../personalProfile/PersonalProfile';
import ErrorBoundary from '../common/components/errorBoundaries/ErrorBoundary';

export default function DoctorUserProfile(props) {
    const { user } = props;

    const [currentComponent, setCurrentComponent] = useState('Perfil Público');

    // const navigate = useNavigate();
    // const sendToHome = () => navigate('/', { replace: true });

    return (
        <ErrorBoundary>
            <div className="lg:grid lg:grid-cols-12">
                <DoctorProfileNavigation
                    selectedItem={currentComponent}
                    updateNavigation={(newComponent) => setCurrentComponent(newComponent)}
                />
                {currentComponent === 'Perfil Público' && <DoctorOwnProfile doctorUser={user} />}
                {currentComponent === 'Información Personal' && <PersonalInfo userType="doctor" />}
            </div>
        </ErrorBoundary>
    );
}
