import { Fragment } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { DotsVerticalIcon } from '@heroicons/react/outline';
import { format, parseISO } from 'date-fns';
import { useNavigate } from 'react-router-dom';

function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
}

export default function AppointmentItem(props) {
    const { appointment, setSelectedAppointment, onRescheduleAppointment } = props;

    let startDateTime = parseISO(`${appointment.appDate}T${appointment.appTime}`);

    const _onViewDetails = (event, appointment) => {
        event.preventDefault();
        setSelectedAppointment(appointment);
    };

    const _onRescheduleDetails = (event, appointment) => {
        event.preventDefault();
        onRescheduleAppointment(appointment);
    };

    return (
        <li className="flex items-center px-4 py-2 space-x-4 group rounded-xl focus-within:bg-gray-100 hover:bg-gray-100">
            {/* In case someday users have avatars */}
            {/*<img src={meeting.imageUrl} alt="" className="flex-none w-10 h-10 rounded-full" />*/}
            <div className="flex-auto">
                <p className="text-gray-900">{appointment.userFullName}</p>
                <p className="mt-0.5">
                    <time dateTime={startDateTime}>{format(startDateTime, 'h:mm a')}</time>
                </p>
            </div>
            <Menu as="div" className="relative opacity-0 focus-within:opacity-100 group-hover:opacity-100">
                <div>
                    <Menu.Button className="-m-2 flex items-center rounded-full p-1.5 text-gray-500 hover:text-gray-600">
                        <span className="sr-only">Abrir opciones</span>
                        <DotsVerticalIcon className="w-6 h-6" aria-hidden="true" />
                    </Menu.Button>
                </div>

                <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                >
                    <Menu.Items className="absolute right-0 z-10 mt-2 origin-top-right bg-white rounded-md shadow-lg w-36 ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <div className="py-1">
                            <Menu.Item>
                                {({ active }) => (
                                    <a
                                        href="#"
                                        className={classNames(
                                            active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                            'block px-4 py-2 text-base'
                                        )}
                                        onClick={(e) => _onViewDetails(e, appointment)}
                                    >
                                        Ver Detalles
                                    </a>
                                )}
                            </Menu.Item>
                            <Menu.Item>
                                {({ active }) => (
                                    <a
                                        href="#"
                                        className={classNames(
                                            active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                            'block px-4 py-2 text-base'
                                        )}
                                        onClick={(e) => _onRescheduleDetails(e, appointment)}
                                    >
                                        Reprogramar
                                    </a>
                                )}
                            </Menu.Item>
                        </div>
                    </Menu.Items>
                </Transition>
            </Menu>
        </li>
    );
}
