import { useForm } from "react-hook-form";
import { useEffect, useState } from 'react';
import getCity from "../../api/getCity";
import { useNavigate } from "react-router-dom";
import { buildSearch } from "../../common/utils/url";
import fetchAuthenticatedUser from "../../api/fetchAuthenticatedUser";

const useSearch = () => {
  const [formError, setFormError] = useState(false);
  const [error, setError] = useState('');
  const [cities, setCities] = useState([]);
  const navigate = useNavigate();
  const {
    control,
    handleSubmit,
    register
  } = useForm();

  const onSearch = async ({ specialty, date: dateData, city}) => {
    let date;
    setError('');
    if (specialty || dateData || city ) {
      setFormError(false);
      if (dateData) {
        date = dateData.toJSON().slice(0, 10);
      }
      
      const user = await fetchAuthenticatedUser();
      if(user.id)
        navigate({
          pathname: '/search-results',
          search: buildSearch({ q: specialty, date, city: city?.id })
        })
      else
        setError('Tienes que iniciar sesión para realizar la búsqueda');
    } else {
      setFormError(true);
    }
  }

  useEffect(() => { 
     const getData = async () => {
      const data = await getCity(setError);
      setCities(data.map((city) => ({
        ...city,
        label: city.name,
        value: city.name
      })));
     };
  
     getData();
  }, []);



  return {
    control,
    handleSubmit,
    onSearch,
    formError,
    register,
    cities,
    error
  }
};


export default useSearch;
