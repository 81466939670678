import { CalendarIcon, SearchIcon } from '@heroicons/react/outline';
import imgChainLogo from './../../images/dl-chain-logo.png';
import drWork from './../../images/doctor-work.jpg';
import { Controller } from 'react-hook-form';
import useSearch from '../hooks/useSearch';
import FormInputField from '../../common/components/formElements/FormInputField';
import FormDatePicker from '../../common/components/formElements/FormDatePicker';
import FormComboBox from '../../common/components/formElements/FormComboBox';
import { Link } from 'react-router-dom';

const auxDate = new Date()
const MIN_DATE = new Date(auxDate.setDate(auxDate.getDate() + 1));
export default function Search() {
    const {
        onSearch,
        register,
        handleSubmit,
        control,
        formError,
        cities,
        error
    } = useSearch();

    return (
        <div className="bg-dl-screen-lightblue py-12 lg:px-8 mx-auto px-4 relative sm:px-6">
            <div className="grid grid-cols-12 gap-6">
                <div className="col-span-12 md:col-span-6">
                    <form onSubmit={handleSubmit(onSearch)}
                        style={{ margin: '0 auto' }}
                        className="justify-center max-w-xl py-10 flex-row">
                        <h2 className="text-2xl font-extrabold tracking-tight text-dl-accent sm:text-3xl pb-5">
                            <span className="block">Encuentra el doctor para tu cita</span>
                        </h2>
                        { formError && <p className="text-sm text-red-700">Debe llenar al menos un campo</p> }
                        { error !== '' && 
                          (<div className="mb-8 bg-blue-100 border-t-4 border-blue-500 rounded-b text-blue-900 px-4 py-3 shadow-md" role="alert">
                          <div className="flex">
                            <div className="py-1"><svg className="fill-current h-6 w-6 text-blue-500 mr-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z"/></svg></div>
                            <div>
                              <p className="font-bold mb-4">{error}</p>
                              <Link to='/sign-in' className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded'>
                                Inicia sesión
                              </Link>
                            </div>
                          </div>
                        </div>)
                        }
                        <div className='flex flex-col gap-4'>
                        <FormInputField
                            label="Buscar por especialidad o nombre"
                            type="text"
                            name="buscar"
                            id="buscar"
                            placeholder={'Buscar por especialidad o nombre'}
                            iconComponent={<SearchIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />}
                            registration={register('specialty')}
                        />

                        <Controller
                            control={control}
                            name='city'
                            render={({ field }) => (
                                <FormComboBox
                                    label="Seleccionar ubicación"
                                    data={cities}
                                    control={control}
                                    value={field.value}
                                    onChange={(value) => field.onChange(value)}
                                />
                            )}
                        />

                        <Controller
                            control={control}
                            name='date'
                            render={({ field }) => (
                               <FormDatePicker
                                 identifier='date'
                                 label='Seleccionar fecha'
                                 iconComponent={<CalendarIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />}
                                 datePickerProps={{
                                    selected: field.value,
                                    dateFormat: 'dd/MM/yyyy',
                                    onChange: (date) => field.onChange(date),
                                    minDate: MIN_DATE,
                                    peekNextMonth: true,
                                    showMonthDropdown: true,
                                    showYearDropdown: false,
                                    dropdownMode: 'select'
                                 }}
                               />
                            )}
                        />
                        </div>
                       

                        <div className="">
                            <button
                                type="submit"
                                className="w-full flex justify-center mt-5 px-4 py-2 border border-transparent text-base font-medium rounded-full shadow-sm text-white bg-blue-600 hover:bg-blue-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-400"
                            >
                                <div>
                                    <img
                                        className="h-8 w-auto mr-1.5 flex justify-center"
                                        src={imgChainLogo}
                                        alt="DoctoLink"
                                    />
                                </div>
                                Buscar
                            </button>
                        </div>
                    </form>
                </div>
                <div className="col-span-12 md:col-span-6 relative">
                    <img
                        className="absolute inset-0 h-full w-full object-cover"
                        src={drWork}
                        alt=""
                    />
                </div>
            </div>
        </div>
    );
}
