import { useState } from "react";
import { useForm } from "react-hook-form";
import { formattedCountryData, personalDocuments } from "../../../common/utils/formattedData";
import { updateDoctorPersonalInfo as updateDoctorInfo } from '../api/updateDoctorPersonalInfo';
import { updatePatientPersonalInfo as updatePatientInfo } from "../api/updatePatientPersonalInfo";

const useInitialForm = (userType, userProfile, userId) => {
    const [ showSuccessModal, setShowSuccessModal ] = useState(false);
    const [ showErrorModal, setShowErrorModal ] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const nationality =
      formattedCountryData.find((nationality) => nationality.label = userProfile.nationality);
    const {
        register,
        handleSubmit,
        formState: { errors },
        control
    } = useForm({
      defaultValues: 
        { 
          ...userProfile,
          nationality,
          birthDate: new Date(userProfile.birthDate).toLocaleDateString()
        }
    });

  

  const saveProfileForm = async ({ phone }) => {

     setIsLoading(true);


     const onFail = () =>  {
       setShowErrorModal(true);
     };
  
     const data = userType === 'doctor'
       ? await updateDoctorInfo(userId, { phone }, onFail)
       : await updatePatientInfo(userId, { phone }, onFail);
     
     setIsLoading(false);

     if (data) {
       setShowSuccessModal(true);
     }
  };

  const onCloseErrorMessage = () => {
    setShowErrorModal(false);
  };

  const onCloseSuccessMessage = () => {
    setShowSuccessModal(false);
  }

  return {
    formattedCountryData,
    register,
    handleSubmit,
    errors,
    saveProfileForm,
    control,
    personalDocuments,
    showErrorModal,
    isLoading,
    showSuccessModal,
    onCloseErrorMessage,
    onCloseSuccessMessage
  }
};

export default useInitialForm;
