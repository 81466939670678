import NavigationElements from "./pageElements/NavigationElements";
import ContactUsFormElement from "./pageElements/ContactUsFormElement";
import ContactUsFAQSElement from "./pageElements/ContactUsFAQSElement";

export default function ContactUsPage(props) {

    const {displayNavigation, onUpdateDisplayNavigation} = props;

    return <>
        <NavigationElements
            displayNavigation={displayNavigation}
            onUpdateDisplayNavigation={onUpdateDisplayNavigation}
        />
        <ContactUsFormElement />
        <ContactUsFAQSElement />
    </>
}
