import { useState } from 'react';
import ProfileFormHeader from '../../../ProfileFormHeader/ProfileFormHeader';
import HalfRow from '../../../../../common/components/formElements/HalfRow';
import FormInputField from '../../../../../common/components/formElements/FormInputField';
import { CalendarIcon, MailIcon, PhoneIcon, UsersIcon, ClockIcon } from '@heroicons/react/outline';
import FullRow from '../../../../../common/components/formElements/FullRow';
import FormTextArea from '../../../../../common/components/formElements/FormTextArea';
import useAppointmentDetails from './hooks/useAppointmentDetails';
import CustomRedAlert from '../../../../../common/components/customRedAlert/CustomRedAlert';
import CustomSuccessModal from '../../../../../common/components/customSuccessModal/CustomSuccessModal';

export default function HistoryAppointmentDetails(props) {
    const { appointment, onCancel, selectionMode, onSuccessfulSave } = props;

    const [openReturnAlert, setOpenReturnAlert] = useState(false);

    const {
        handleSubmit,
        register,
        errors,
        isDirty,
        closeErrorMessage,
        onCloseSuccessMessage,
        showSuccessModal,
        showErrorModal,
        errorBody,
        successBody,
        onSaveSubmit
    } = useAppointmentDetails(appointment, onSuccessfulSave, selectionMode);

    if (!selectionMode) {
        return null;
    }

    const _onReturnToAppointments = () => {
        if (isDirty) {
            setOpenReturnAlert(true);
        } else {
            onCancel();
        }
    };

    const _onConfirmCancelAppointment = () => {
        setOpenReturnAlert(false);
        onCancel();
    };

    const _onCancelConfirmationForClosing = () => {
        setOpenReturnAlert(false);
    };

    return (
        <form onSubmit={handleSubmit(onSaveSubmit)}>
            <div className="shadow sm:rounded-md sm:overflow-hidden">
                <div className="bg-white py-6 px-4 space-y-6 sm:p-6">
                    {selectionMode !== 'viewOnly' && (
                        <ProfileFormHeader
                            title={selectionMode === 'complete' ? 'Completar Cita' : 'Solicitar Reprogramación'}
                            textColor={selectionMode === 'reschedule' ? 'text-dl-red' : null}
                        />
                    )}
                    <div>
                        <h3 className="text-lg leading-6 font-medium text-dl-secondary">Detalles del paciente</h3>
                    </div>
                    <div className="grid grid-cols-6 gap-6">
                        <HalfRow>
                            <FormInputField
                                identifier="userFullName"
                                label="Nombre del Paciente"
                                iconComponent={<UsersIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />}
                                type="string"
                                registration={register('userFullName', {
                                    required: true
                                })}
                                disabled
                            />
                        </HalfRow>
                        <HalfRow>
                            <FormInputField
                                identifier="birthDate"
                                label="Fecha de Nacimiento"
                                iconComponent={<CalendarIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />}
                                type="string"
                                registration={register('birthDate', {
                                    required: true
                                })}
                                disabled
                            />
                        </HalfRow>
                        <HalfRow>
                            <FormInputField
                                identifier="appDate"
                                label="Fecha de cita"
                                iconComponent={<CalendarIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />}
                                type="string"
                                registration={register('appDate', {
                                    required: true
                                })}
                                disabled
                            />
                        </HalfRow>
                        <HalfRow>
                            <FormInputField
                                identifier="appTime"
                                label="Hora de cita"
                                iconComponent={<ClockIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />}
                                type="string"
                                registration={register('appTime', {
                                    required: true
                                })}
                                disabled
                            />
                        </HalfRow>
                        <HalfRow>
                            <FormInputField
                                identifier="userPhone"
                                label="Teléfono"
                                iconComponent={<PhoneIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />}
                                type="string"
                                registration={register('userPhone', {
                                    required: true
                                })}
                                disabled
                            />
                        </HalfRow>
                        <HalfRow>
                            <FormInputField
                                identifier="userEmail"
                                label="Correo Electrónico"
                                iconComponent={<MailIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />}
                                type="string"
                                registration={register('userEmail', {
                                    required: true
                                })}
                                disabled
                            />
                        </HalfRow>
                        <FullRow>
                            <FormInputField
                                identifier="motiveName"
                                label="Motivo de consulta"
                                type="string"
                                registration={register('motiveName', {
                                    required: true
                                })}
                                disabled
                            />
                            {errors.phone ? (
                                <p className="text-red-700 font-light">Este campo no puede ser vacío</p>
                            ) : null}
                        </FullRow>
                        <FullRow>
                            <FormTextArea
                                label={'Notas del paciente'}
                                identifier={'userNotes'}
                                rows={3}
                                registration={register('userNotes')}
                                disabled
                            />
                        </FullRow>
                        <FullRow>
                            <FormTextArea
                                label={
                                    selectionMode === 'completion' || selectionMode === 'viewOnly'
                                        ? 'Notas del Doctor'
                                        : 'Mensaje al usuario'
                                }
                                placeholder={
                                    selectionMode === 'reschedule'
                                        ? `Ej. Por motivos de viaje no podre atenderle, por favor reprograme para después del 20 de octubre. Muchas gracias.`
                                        : null
                                }
                                identifier={'doctorNotes'}
                                rows={3}
                                registration={register('doctorNotes', { required: true })}
                                helperText={'Requerido para marcar como completado o reprogramar'}
                                errorMessage={errors.doctorNotes ? 'Este campo es requerido' : null}
                                disabled={selectionMode === 'viewOnly'}
                            />
                        </FullRow>
                        <FullRow>
                            <FormInputField
                                identifier="statusName"
                                label="Estado actual de cita"
                                type="string"
                                registration={register('statusName', {
                                    required: true
                                })}
                                disabled
                            />
                        </FullRow>
                    </div>
                </div>
                <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
                    {/*<button
                        type="button"
                        onClick={onCancel}
                        className="bg-dl-red border border-transparent rounded-full shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-dl-primary-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-dl-red"
                    >
                        Cerrar
                    </button>*/}
                    <button
                        type="button"
                        onClick={_onReturnToAppointments}
                        className="mr-2 rounded-full border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-dl-accent focus:ring-offset-2"
                    >
                        Regresar
                    </button>
                    {selectionMode === 'complete' && (
                        <button
                            type="submit"
                            className="bg-dl-primary-900 border border-transparent rounded-full shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-dl-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-dl-primary-700"
                        >
                            Completar Cita
                        </button>
                    )}

                    {selectionMode === 'reschedule' && (
                        <button
                            type="submit"
                            className="bg-dl-red border border-transparent rounded-full shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-[#f16782] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-dl-accent"
                        >
                            Reprogramar Cita
                        </button>
                    )}
                </div>
            </div>

            {openReturnAlert && (
                <CustomRedAlert
                    open={openReturnAlert}
                    title={'¿Desea descartar los cambios?'}
                    bodyText={'La cita ha sido modificada, por favor confirme que desea salir de esta vista.'}
                    onClose={_onCancelConfirmationForClosing}
                    mainActionText={'Salir'}
                    onMainAction={_onConfirmCancelAppointment}
                    secondaryActionText={'Cancelar'}
                    onSecondaryAction={_onCancelConfirmationForClosing}
                />
            )}

            {showErrorModal && (
                <CustomRedAlert
                    open={showErrorModal}
                    title={'Ocurrió un error.'}
                    bodyText={errorBody}
                    onClose={closeErrorMessage}
                />
            )}

            {showSuccessModal && (
                <CustomSuccessModal
                    title={'Registro actualizado con éxito'}
                    bodyText={successBody}
                    buttonText={'Aceptar'}
                    buttonAction={onCloseSuccessMessage}
                    open={showSuccessModal}
                    onClose={onCloseSuccessMessage}
                />
            )}
        </form>
    );
}
