import DatePicker, { registerLocale } from "react-datepicker";


const FormDatePicker = (props) => {
    const {
        label,
        identifier,
        datePickerProps,
        helperText,
        errorMessage,
        iconComponent
    } = props;

    return (
        <div>
            <label htmlFor={identifier} className="block text-sm font-medium text-dl-accent">
                {label}
            </label>
            <div className="relative mt-1 rounded-md shadow-sm">
                <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                    {iconComponent}
                </div>

                <DatePicker
                   {...datePickerProps}
                   locale='es'
                    className='mt-1 block w-full border border-gray-300 rounded-full shadow-sm py-2 px-3 focus:outline-none focus:ring-dl-primary-700 focus:border-dl-primary-700 sm:text-sm'
                />

            </div>
            {!errorMessage && helperText && (
                <p className="mt-2 text-sm text-gray-500" id={`${identifier}-description`}>
                    {helperText}
                </p>
            )}

            {errorMessage ? <p className="mt-2 text-sm text-red-700">{errorMessage}</p> : null}
        </div>
    );
};

export default FormDatePicker;
