import { KeyIcon } from '@heroicons/react/outline'

const navigation = [
    { name: 'Información Personal', href: '#', icon: KeyIcon, current: true },
];

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function PatientUserProfileElement(props) {
    const { selectedItem, updateNavigation } = props;

    navigation.forEach((element, index) => {
        navigation[index].current = element.name === selectedItem;
    });

    const _onLinkClick = (event, tabName) => {
        event.preventDefault();
        updateNavigation(tabName);
    };

    return (
            <aside className="bg-dl-screen-gray py-6 px-2 sm:px-6 lg:py-0 lg:px-0 lg:col-span-3">
                <nav className="space-y-1 ml-3 mt-5">
                    {navigation.map((item) => (
                        <a
                            key={item.name}
                            href={item.href}
                            className={classNames(
                                item.current
                                    ? 'bg-gray-50 text-blue-700 hover:text-blue-700 hover:bg-white'
                                    : 'text-gray-900 hover:text-gray-900 hover:bg-gray-50',
                                'group rounded-md px-3 py-2 flex items-center text-sm font-medium'
                            )}
                            aria-current={item.current ? 'page' : undefined}
                            onClick={(e) => _onLinkClick(e, item.name)}
                        >
                            <item.icon
                                className={classNames(
                                    item.current
                                        ? 'text-blue-500 group-hover:text-blue-500'
                                        : 'text-gray-400 group-hover:text-gray-500',
                                    'flex-shrink-0 -ml-1 mr-3 h-6 w-6'
                                )}
                                aria-hidden="true"
                            />
                            <span className="truncate">{item.name}</span>
                        </a>
                    ))}
                </nav>
            </aside>
    );
}
