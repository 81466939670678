import { CalendarIcon } from '@heroicons/react/outline';
import { LocationMarkerIcon } from '@heroicons/react/solid';
import { format, parseISO } from 'date-fns';
import { Link } from 'react-router-dom';
import useAppointmentSuccess from '../../hooks/useAppointmentSuccess';

export default function AppointmentSuccess() {
    const { doctorData: docData, selectedAppointment } = useAppointmentSuccess();
    const parsedAppointment =
        selectedAppointment !== null ? parseISO(`${selectedAppointment.date}T${selectedAppointment.appTime}`) : null;
    return (
        <main className="bg-white px-4 pt-16 pb-24 sm:px-6 sm:pt-24 lg:px-8 lg:py-32 min-h-[90vh]">
            <div className="max-w-3xl mx-auto">
                <div className="max-w-xl">
                    <h1 className="text-2xl font-semibold uppercase tracking-wide text-blue-600">¡Listo!</h1>
                    <p className="mt-2 text-4xl tracking-tight sm:text-5xl">
                        La cita ha sido agendada exitosamente
                    </p>
                    <p className="mt-2 text-base text-gray-500">.</p>
                    <Link
                        to='/appointment-manager'
                        className='bg-blue-500 hover:bg-blue-700 text-white text-base font-normal font-bold py-2 px-4 rounded'>
                        Ir a mis citas pendientes
                    </Link>

                    {parsedAppointment !== null ? (
                        <div className="bg-green-100 border-l-4 border-green-500 text-green-700 p-4 mt-8" role="alert">
                            <p>Fecha seleccionada:</p>
                            <span className="font-bold">
                                {`${new Date(selectedAppointment.date).toLocaleString('es', {
                                    weekday: 'long',
                                    year: 'numeric',
                                    month: 'long',
                                    day: 'numeric'
                                })}`}
                            </span>{' '}
                            {','}
                            <time className="font-bold" dateTime={parsedAppointment}>
                                {format(parsedAppointment, 'h:mm a')}
                            </time>
                        </div>
                    ) : null}

                    <div className="mb-4">
                        <div>
                            <div className="relative flex space-x-6 py-6 xl:static">
                                <img
                                    src={docData.personalImage}
                                    alt="doctor-image"
                                    className="h-14 w-14 flex-none rounded-full"
                                />
                                <div className="flex-auto">
                                    <h3 className="pr-10 font-semibold text-gray-900 xl:pr-0">{docData.fullName}</h3>
                                    <dl className="mt-2 flex flex-col text-gray-500 xl:flex-row">
                                        <div className="flex items-start space-x-3">
                                            <dt className="mt-0.5">
                                                <CalendarIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                            </dt>
                                            <dd>{docData.speciality}</dd>
                                        </div>
                                        <div className="mt-2 flex items-start space-x-3 xl:mt-0 xl:ml-3.5 xl:border-l xl:border-gray-400 xl:border-opacity-50 xl:pl-3.5">
                                            <dt className="mt-0.5">
                                                <LocationMarkerIcon
                                                    className="h-5 w-5 text-gray-400"
                                                    aria-hidden="true"
                                                />
                                            </dt>
                                            <dd>{docData.address}</dd>
                                        </div>
                                    </dl>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    );
}
