/* eslint-disable no-unused-vars */
import {Fragment, useState} from 'react'
import {Disclosure, Listbox, Menu, Transition} from '@headlessui/react'
import {
    ArrowNarrowLeftIcon,
    ArrowNarrowRightIcon,
    BriefcaseIcon,
    CalendarIcon,
    CheckCircleIcon,
    CheckIcon,
    ChevronDownIcon,
    ChevronRightIcon,
    CurrencyDollarIcon,
    LinkIcon,
    LocationMarkerIcon,
    MailIcon,
    PencilIcon,
    SearchIcon
} from '@heroicons/react/solid'
import {BellIcon, MenuIcon, XIcon} from '@heroicons/react/outline'
import {Link} from "react-router-dom";

const user = {
    name: 'Whitney Francis',
    email: 'whitneyfrancis@example.com',
    imageUrl: 'https://images.unsplash.com/photo-1517365830460-955ce3ccd263?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80'
}
const navigation = [
    {
        name: 'Dashboard',
        href: '#',
        current: true
    }, {
        name: 'Jobs',
        href: '#',
        current: false
    }, {
        name: 'Applicants',
        href: '#',
        current: false
    }, {
        name: 'Company',
        href: '#',
        current: false
    },
]
const userNavigation = [
    {
        name: 'Your Profile',
        href: '#'
    }, {
        name: 'Settings',
        href: '#'
    }, {
        name: 'Sign out',
        href: '#'
    },
]
const tabs = [
    {
        name: 'Perfil',
        href: '#',
        current: true
    }, {
        name: 'Horarios',
        href: '#',
        current: false
    }, {
        name: 'Tarifas',
        href: '#',
        current: false
    }, {
        name: 'Info',
        href: '#',
        current: false
    },
]
const candidates = [
    {
        name: 'Dr. Nombre Apellido',
        area: 'Nombre de especialidad',
        imageUrl: 'https://images.unsplash.com/photo-1502685104226-ee32379fefbe?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
        address: 'Clínica 12, Alameda Juan Pablo II, Av. 15 norte, San Salvador'
    },
    // More candidates...
]

const motivoConsulta = [
    {
        title: 'Nombre de motivo de consulta',
    },
    {
        title: 'Nombre de motivo de consulta',
    },
    {
        title: 'Nombre de motivo de consulta',
    },
    {
        title: 'Nombre de motivo de consulta',
    },
    // More motivoConsulta...
]

const profileTab = [
    {
        title: 'Generales',
        description: [
            {
                info: 'Fugiat ipsum ipsum deserunt culpa aute sint do nostrud anim incididunt cillum culpa consequat. Excepteur qui ipsum aliquip consequat sint. Sit id mollit nulla mollit nostrud in ea officia proident. Irure nostrud pariatur mollit ad adipisicing reprehenderit deserunt qui eu.',
            },
            {
                info: 'Fugiat ipsum ipsum deserunt culpa aute sint do nostrud anim incididunt cillum culpa consequat. Excepteur qui ipsum aliquip consequat sint. Sit id mollit nulla mollit nostrud in ea officia proident. Irure nostrud pariatur mollit ad adipisicing reprehenderit deserunt qui eu.',
            }
        ]
    },
    {
        title: 'Idiomas',
        description: [
            {
                info: 'Espanol',
            },
            {
                info: 'Ingles',
            }
        ]
    },
    // More profiles...
]

const publishingOptions = [
    {
        name: 'Published',
        description: 'This job posting can be viewed by anyone who has the link.',
        current: true
    }, {
        name: 'Draft',
        description: 'This job posting will no longer be publicly accessible.',
        current: false
    },
]

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function DoctorResultsTabsElement() {
    const [selected, setSelected] = useState(publishingOptions[0])

    return (
        <> {/*
        This example requires updating your template:

        ```
        <html className="h-full bg-white">
        <body className="h-full">
        ```
      */}
            <div className="min-h-full">

                {/* Page heading */}
                <header className="bg-dl-primary-900/[.1] py-8">
                    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 xl:flex xl:items-center xl:justify-between">
                        <div className="flex-1 min-w-0">
                            <nav className="flex" aria-label="Breadcrumb">
                                <ol role="list" className="flex items-center space-x-4">
                                    <li>
                                        <div>
                                            <a href="#" className="text-sm font-medium text-gray-500 hover:text-gray-700">
                                                Especialidad
                                            </a>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="flex items-center">
                                            <ChevronRightIcon className="flex-shrink-0 h-5 w-5 text-gray-400" aria-hidden="true"/>
                                            <a href="#" className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700">
                                                Nombre doctor
                                            </a>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="flex items-center">
                                            <ChevronRightIcon className="flex-shrink-0 h-5 w-5 text-gray-400" aria-hidden="true"/>
                                            <a href="#" className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700">
                                                Motivo de consulta
                                            </a>
                                        </div>
                                    </li>
                                </ol>
                            </nav>
                            <h1 className="mt-2 text-2xl font-bold leading-7 text-dl-accent sm:text-3xl sm:truncate">
                                Motivo de consulta
                            </h1>

                            {/* Profile */}
                            <ul role="list" className="mt-5 border-t border-gray-200 divide-y divide-gray-200 sm:mt-0 sm:border-t-0">
                                {
                                candidates.map((candidate) => (
                                    <li key={
                                        candidate.email
                                    }>

                                            <div className="flex items-center py-5 px-4 sm:py-6 sm:px-0">
                                                <div className="min-w-0 flex-1 flex items-center">
                                                    <div className="flex-shrink-0">
                                                        <img className="h-40 w-40 rounded-full group-hover:opacity-75 border-2 border-dl-primary-500"
                                                            src={
                                                                candidate.imageUrl
                                                            }
                                                            alt=""/>
                                                    </div>
                                                    <div className="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4">
                                                        <div>
                                                            <p className="text-lg font-medium text-dl-accent truncate">
                                                                {
                                                                candidate.name
                                                            }</p>
                                                            <p className="mt-2 flex items-center text-sm text-gray-500">
                                                                <MailIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true"/>
                                                                <span className="truncate">
                                                                    {
                                                                    candidate.area
                                                                }</span>
                                                            </p>
                                                            <p className="mt-2 flex items-center text-sm text-gray-500">
                                                                <MailIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true"/>
                                                                <span className="">
                                                                    {
                                                                    candidate.address
                                                                }</span>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                    </li>
                                ))
                            } </ul>

                            {/* <div className="mt-1 flex flex-col sm:flex-row sm:flex-wrap sm:mt-0 sm:space-x-8">
                                <div className="mt-2 flex items-center text-sm text-gray-500">
                                    <BriefcaseIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                                    Full-time
                                </div>
                                <div className="mt-2 flex items-center text-sm text-gray-500">
                                    <LocationMarkerIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                                    Remote
                                </div>
                                <div className="mt-2 flex items-center text-sm text-gray-500">
                                    <CurrencyDollarIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                                    $120k &ndash; $140k
                                </div>
                                <div className="mt-2 flex items-center text-sm text-gray-500">
                                    <CalendarIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                                    Closing on January 9, 2020
                                </div>
                            </div> */}
                        </div>

                    </div>
                </header>

                <main className="pt-8 pb-16">
                    <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">

                        {/* Body tabs */}

                                <div className="pt-8 px-4 sm:px-0">

                                    {/* Stacked list */}


                                    {/* Description list*/}
                                    <section aria-labelledby="applicant-information-title">
                                        <div className="bg-white shadow sm:rounded-lg">
                                            <div className="px-4 py-5 sm:px-6">
                                                <h2 id="applicant-information-title" className="text-lg leading-6 font-medium text-dl-secondary">
                                                    Elige motivo de consulta
                                                </h2>

                                            </div>
                                            <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                                                <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">

                                                    <div className="sm:col-span-2">

                                                        <ul role="list" className="mt-5 border-t border-gray-200 divide-y divide-gray-200 sm:mt-0 sm:border-t-0">
                                                            {motivoConsulta.map((item) => (
                                                                <li>
                                                                    <Link to="/doctor-appointment-date" className="group block">
                                                                        <div className="flex items-center py-5 px-4 sm:py-6 sm:px-0">
                                                                            <div className="min-w-0 flex-1 flex items-center">
                                                                                <div className="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4">
                                                                                    <div>
                                                                                        <p className="text-sm font-medium text-dl-accent truncate">{item.title}</p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div>
                                                                                <ChevronRightIcon
                                                                                    className="h-5 w-5 text-gray-400 group-hover:text-gray-700"
                                                                                    aria-hidden="true"
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </Link>
                                                                </li>
                                                            ))}
                                                        </ul>
                                                    </div>
                                                </dl>
                                            </div>
                                        </div>
                                    </section>
                                </div>

                    </div>
                </main>
            </div>
        </>
    )
}
