import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import useAppointmentStore from "../../common/state/useAppointmentStore";
import createDoctorAppointment from "../api/createDoctorAppointment";
import getDoctorAvailableDate from "../api/getDoctorAvailableDate";

const useSelectAppointmentDate = () => {
  const navigate = useNavigate();
  const {
    doctorData,
    selectedMotive: motive,
    selectedAppointment,
    setSelectedAppointment
  } =
    useAppointmentStore(({ 
      doctorData,
      selectedMotive,
      selectedAppointment,
      setSelectedAppointment
    }) => ({
        doctorData,
        selectedMotive,
        setSelectedAppointment,
        selectedAppointment
      }));
 
  const [error, setError] = useState('');
  const [availableAppointments, setAvailableAppointments] = useState([]);
  const [additionalInfo, setAdditionalInfo] = useState('');

  const queryAppointmentHistory = async (startDate, endDate) => {
      const dates = await getDoctorAvailableDate({
        doctorId: doctorData.id,
        startDate,
        endDate,
        setError
      });
      if (dates) {
          setAvailableAppointments(dates);
      }
  };

  const onCloseErrorModal = () => setError('');

  const onCreateDoctorAppointment = async () => {
    const payload = {
      idDoctor: doctorData.id,
      idAppointmentMotive: motive.id,
      idAvailability: selectedAppointment.id,
      appointmentDate: selectedAppointment.date,
      userNotes: additionalInfo
    };

    const response = await createDoctorAppointment(payload, setError);

    if(response) {
      navigate(`/${doctorData.id}/${doctorData.fullName?.replace(' ',  '-')}/appointment-success`)
    }
  };

  const onSetAdditionalInfo = (e) => setAdditionalInfo(e.target.value);
  
  useEffect(() => {
    const now = new Date();
    const tomorrow = new Date();
    tomorrow.setDate(now.getDate() + 1);
    const endDate = new Date(tomorrow.getFullYear(), tomorrow.getMonth() + 1, 0);
    queryAppointmentHistory(tomorrow.toISOString().slice(0, 10), endDate.toISOString().slice(0, 10));
  }, []);

  return {
    docData: doctorData,
    motive,
    setSelectedAppointment,
    availableAppointments,
    selectedAppointment,
    queryAppointmentHistory,
    error, 
    additionalInfo,
    onSetAdditionalInfo,
    onCreateDoctorAppointment,
    onCloseErrorModal
  }
};

export default useSelectAppointmentDate;
