const AuthBO = {
    validateLogin(email, password) {
        if (!email) {
            throw new Error('No email parameter was provided');
        }

        if (!password) {
            throw new Error('No password parameter was provided');
        }

        if (email === '') {
            throw new Error('Email parameter was empty');
        }

        if (password === '') {
            throw new Error('Password parameter was empty');
        }

        return true;
    },
    validateForgotPassword(email) {
        if (!email) {
            throw new Error('No email parameter was provided');
        }

        return true;
    },
    validateNewPassword(password, confirmPassword) {
        if (!password) {
            throw new Error('validateNewPassword: No password parameter was provided');
        }

        if (!confirmPassword) {
            throw new Error('validateNewPassword: No confirmPassword parameter was provided');
        }

        return password === confirmPassword;
    }
};

export default AuthBO;
