import navigation from './navigation';

export default function Footer() {
    return (
        <footer className="bg-dl-accent min-h-250px">
            <div className="max-w-7xl mx-auto py-12 px-4 overflow-hidden sm:px-6 lg:px-8">
                <nav className="-mx-5 -my-2 flex flex-wrap justify-center" aria-label="Footer">
                    {navigation.main.map((item) => (
                        <div key={item.name} className="px-5 py-2">
                            <a
                                href={item.href}
                                className="text-base text-dl-primary-900 hover:text-dl-primary-500"
                            >
                                {item.name}
                            </a>
                        </div>
                    ))}
                </nav>
                <div className="mt-8 flex justify-center space-x-6">
                    {navigation.social.map((item) => (
                        <a
                            key={item.name}
                            href={item.href}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="text-dl-primary-900 hover:text-dl-primary-500"
                        >
                            <span className="sr-only">{item.name}</span>
                            <item.icon className="h-6 w-6" aria-hidden="true" />
                        </a>
                    ))}
                </div>
                <p className="mt-8 text-center text-base text-gray-400">
                    &copy; {new Date().getFullYear()} DoctoLink, Inc. All rights reserved.
                </p>
            </div>
        </footer>
    );
}
