export default function ProfileFormHeader(props) {
    const { title, initialText, secondaryText, textColor = 'text-gray-900' } = props;

    return (
        <div>
            <h3 className={`text-lg leading-6 font-medium ${textColor}`}>{title}</h3>
            {initialText && <p className="mt-1 text-sm text-gray-500">{initialText}</p>}
            {secondaryText && <p className="mt-1 text-sm text-gray-500">{secondaryText}</p>}
        </div>
    );
}
