import { CheckCircleIcon } from "@heroicons/react/outline";
import { Link } from "react-router-dom";

const ResultItem = ({ person }) => (
    <li className="col-span-1 bg-white rounded-lg shadow divide-y divide-gray-200">
        <div>
            <div className="w-full flex items-center justify-between py-3 px-6 space-x-6">
                <div className="flex-1 truncate">
                    <div className="flex items-center space-x-3">
                        <h3 className="text-dl-accent text-sm font-medium truncate">{person.fullName}</h3>
                        <span className="flex-shrink-0 inline-block px-2 py-0.5 text-dl-primary-900 text-xs font-medium bg-dl-primary-900/[.1] rounded-full">
                            {person.speciality}
                        </span>
                    </div>
                   <p className="mt-1 text-gray-500 text-sm truncate">{person.city}</p>
                </div>
                <img className="w-16 h-16 bg-gray-300 rounded-full flex-shrink-0" src={person.personalImage} alt="" />
            </div>
        </div>
        <div>
            <div className="-mt-px flex divide-x divide-gray-200">
                {/*
                <div className="w-0 flex-1 flex">
                    <p
                        className="py-2"
                    >
                        <span className="ml-3 text-gray-500 text-xs block w-full">Fecha próxima:</span>

                        <span className="ml-3 text-dl-accent text-sm block w-full">Lunes 16 de febrero 2022 <br></br> 07:00 am</span>
                    </p>
                </div>*/}
                <div className="-ml-px w-0 flex-1 flex">
                    <Link to={`/doctor-results/${person.id}/${person.fullName.replace(' ', '-')}`}
                        className="relative w-0 flex-1 inline-flex items-center justify-center py-4 text-sm text-dl-accent font-medium border border-transparent rounded-br-lg hover:text-dl-accent/[.5]"
                    >
                        <div className="inline-flex items-center justify-center bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                            <CheckCircleIcon className="w-5 h-5 text-dl-accent" aria-hidden="true" />
                            <span className="ml-3">Haz tu cita</span>
                        </div>
                    </Link>
                </div>
            </div>
        </div>
    </li>
);

export default ResultItem;
