import Search from './components/Search';
import Marketing from './components/Marketing';
import Ads from './components/Ads';
import Specialty from './components/Specialty';
import Divider from '../common/components/Divider';
import DoctorInformation from './components/DoctorInformation';
import useAds from '../api/hooks/useAds';
import Calendar from './components/Calendar';
import { useEffect, useRef } from 'react';

export default function Home(props) {
    const { user } = props;
    const { adsToDisplay, onClickAd } = useAds();
    const isDoctor = !!user && user.usertype === 2;

    useEffect(()  => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            {/*Search*/}
            {
               !isDoctor ? <Search /> : <Calendar />
            }
           
            {/*Slider*/}
            <Marketing />
            {/*Ads*/}
            <Ads adsToDisplay={adsToDisplay} onClickAd={onClickAd} />
            {/*Support*/}
            {/* <HelpAndSupportPage /> */}
            {/* Specialty List */}
            <div className="bg-dl-screen-lightblue py-12 lg:px-8 mx-auto px-4 relative sm:px-6">
                <Specialty />
                <Divider />
            </div>
            {/* Support Home div */}
            <DoctorInformation />
            {/* <ContactUsForDoctorsElement /> */}
            {/* Apps downloads */}
            {/* Mientras no tengamos las apps subidas a los markets no estara habilitado este componente */}
            {/*<DownloadAppElement />*/}
            {/* Footer */}
        </>
    );
}
