import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import useAppointmentStore from "../../common/state/useAppointmentStore";
import shallow from 'zustand/shallow';
import fetchDoctorProfile from "../api/fetchDoctorProfile";
import { groupBy } from "../../common/utils/formattedData";

const daySorter = {
    "Lunes": 1,
    "Martes": 2,
    "Miercoles": 3,
    "Jueves": 4,
    "Viernes": 5,
    "Sabado": 6,
    "Domingo": 7
  }
const useDoctorResults = () => {
  const { doctorId } = useParams();
  const { doctorData, setDoctorData } = useAppointmentStore(({ doctorData, setDoctorData}) => ({
    doctorData, setDoctorData
  }), shallow);
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [tabs, setTabs] = useState([
        {
            id: 'general',
            name: 'Perfil',
            current: true,
            sections: []
        }, 
        {
            id: 'horarios',
            name: 'Horarios',
            current: false,
            sections: []
        },
        {
            id: 'tarifas',
            name: 'Tarifas',
            current: false,
            sections: []

        },
       {
            id: 'info',
            name: 'Info',
            current: false,
            sections: []
        }
  ]);

  const onTabSelected = (id) => {
    setTabs((currentTabs) => currentTabs.map((tab) => ({
        ...tab,
        current: id === tab.id
    })));
  }

  const generateTabSections = (tab, docData) => {
    let sections = [];
    const groupedSchedules = groupBy(docData.schedules
                            .sort((a, b) => {
                                const day1 = a.dayName;
                                const day2 = b.dayName;
                                return daySorter[day1] - daySorter[day2];
                            }), 'dayName');
    const mappedSchedules =  Object.keys(groupedSchedules)
                            .map((key) => {
                                return `${key}:${groupedSchedules[key]
                                    .reduce((acc, item) => `${acc} ${acc !== '' ? ',' : ''} ${item.openHours1} a ${item.openHours2}`, '')}`
                            });
                            
     switch (tab.id) {
         case 'general':
             sections = [
                 {
                     name: 'Generales',
                     properties: docData.generalInfo
                       ?? 'Doctolink te invita a que acudas a tu consulta en la fecha y hora indicada. Indica al doctor en la sección de notas si consideras que debe saber algo antes de recibirte.'
                 },
                 {
                     name: 'Idiomas',
                     properties: docData.langauges.map((item) => item.languageName)
                 },
                 {
                    name: 'Educación',
                    properties: docData.education.map((item) => (<>
                      <strong>{item.educationName}</strong>
                      <br/>
                      <span>{item.educationDetail}</span>
                    </>))
                 },
                 {
                    name: 'Experiencia',
                    properties: docData.experience.map((item) => (<>
                      <strong>{item.experienceName}</strong>
                      <br/>
                      <span>{item.experienceDetail}</span>
                    </>))
                 }
              ]
             break;
         case 'tarifas':
             sections = [
                 {
                     name: 'Consulta',
                     properties: docData.consultationCost
                 },
                 {
                     name: 'Examenes',
                     properties: docData.examCost
                 },
                 {
                    name: 'Formas de pago',
                    properties: docData.paymentMethods
                 },
                 {
                    name: 'Información de seguro',
                    properties: docData.insurance
                 }
             ]
             break;
         case 'horarios': 
             sections = [
                 {
                     name: 'Horarios',
                     properties: mappedSchedules
                 }
             ];
          break;
         case 'info':
            sections = [
                {
                    name: 'Contacto 1',
                    properties: docData.patientContactPhone1
                },
                {
                    name: 'Contacto 2',
                    properties: docData.patientContactPhone2
                },
                {
                    name: 'Permiso',
                    properties: docData.governmentPermitInfo
                }
            ];
           break;
         default:
             break;
     };
     return { ...tab, sections };
 };

  useEffect(() => {

    const onFail = () => setError('Error obteniendo información de doctor');

    const fetchData = async () => {
        setIsLoading(true);
        const docData = await fetchDoctorProfile(doctorId, onFail);
        if (docData) {
            setDoctorData(docData);

            setTabs((currentTabs) => currentTabs.map((tab) => generateTabSections(tab, docData)));
        }
         
    };

    fetchData();
  }, []);

  return {
    doctorData,
    error,
    isLoading,
    tabs,
    onTabSelected
  }
};

export default useDoctorResults;
