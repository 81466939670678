import { useEffect, useState } from 'react';
import useDoctorAppointmentStore from '../../../../common/state/useDoctorAppointmentStore';
import getAppointmentHistory from '../api/getAppointmentHistory';

export default function usePendingAppointments() {
    const [pendingAppointments, setPendingAppointments] = useState();
    const [errorMessage, setErrorMessage] = useState(false);
    const {
        selectedAppointment,
        setSelectedAppointment,
        selectionMode,
        setSelectionMode
    } = useDoctorAppointmentStore((state) => ({
        selectedAppointment: state.selectedAppointment,
        setSelectedAppointment: state.setSelectedAppointment,
        selectionMode: state.selectionMode,
        setSelectionMode: state.setSelectionMode
    }));

    const queryAppointmentHistory = async () => {
        const getHistory = await getAppointmentHistory(setErrorMessage);

        if (getHistory && getHistory.pending) {
            setPendingAppointments(getHistory.pending);
            return;
        }

        setErrorMessage('No se pudo completar la solicitud de citas. Inténtelo de nuevo.');
    };

    useEffect(() => {
        queryAppointmentHistory();
    }, []);

    return {
        pendingAppointments,
        errorMessage,
        setErrorMessage,
        queryAppointmentHistory,
        setSelectedAppointment,
        selectedAppointment,
        selectionMode,
        setSelectionMode
    };
}
