import './App.css';

import { useEffect, useState } from 'react';
import es from 'date-fns/locale/es';
import { registerLocale, setDefaultLocale } from 'react-datepicker';
import { HashRouter, Route, Routes } from 'react-router-dom';
import DoctorProfilePage from './components/DoctorProfilePage';
import SignUpPage from './signUp/SignUpPage';
import ResultsPage2 from './components/ResultsPage';
import DoctorResultsPage2 from './components/DoctorResultsPage';
import DoctorDetailsPage from './components/DoctorDetailsPage';
import AppointmentCreationPage from './components/AppointmentCreationPage';
import AppointmentConfirmationPage from './components/AppointmentConfirmationPage';
import PatientProfilePage from './components/PatientProfilePage';
import DoctorsReviewPage from './components/DoctorsReviewPage';
import AboutUsPage from './components/AboutUsPage';
import ContactUsPage from './components/ContactUsPage';
import DoctorAppointmenDateSelectionPage from './components/DoctorAppointmenDateSelectionPage';
import Home from './home/Home';
import WhoWeAre from './whoWeAre/WhoWeAre';
import WhatIsDoctolink from './whatIsDoctolink/WhatIsDoctolink';
import SignIn from './auth/SignIn';
import useAuthentication from './api/hooks/useAuthentication';
import SignInDoctor from './auth/SignInDoctor';
import DoctorUserProfile from './doctorUser/DoctorUserProfile';
import Header from './nav/header/Header';
import Footer from './nav/footer/Footer';
import ConfirmAccountPatient from './confirmAccount/ConfirmAccountPatient';
import ConfirmAccountDoctor from './confirmAccount/ConfirmAccountDoctor';
import ForgotPassword from './auth/forgotPassword/ForgotPassword';
import ForgotPasswordDoctor from './auth/forgotPassword/ForgotPasswordDoctor';
import ResetPassword from './auth/resetPassword/ResetPassword';
import ResetPasswordDoctor from './auth/resetPassword/ResetPasswordDoctor';
import CleanSession from './common/helpers/cleanSession/CleanSession';

import 'react-datepicker/dist/react-datepicker.min.css';
import SideBySideImage from './components/SideBySideImage';
import SearchResults from './searchResults/SearchResults';
import DoctorResultsTabsElement from './doctorResults/components/doctorResultsTabsElement/DoctorResultsTabsElement';
import SelectAppointment from './selectAppointmentMotive/components/selectAppointment/SelectAppointment';
import SelectAppointmentDate from './selectAppointmentDate/components/selectAppointmentDate/SelectAppointmentDate';
import AppointmentSuccess from './appointmentSuccess/components/appointmentSuccess/AppointmentSuccess';
import DoctorAppointmentManager from './doctorAppointmentManager/DoctorAppointmentManager';
import PatientAppointmentManager from './patientAppointmentManager/PatientAppointmentManager';

registerLocale('es', es);
setDefaultLocale('es');

function App() {
    const [displayNavigation, setDisplayNavigation] = useState(false);

    const {
        performUserLogout,
        checkIfLoggedIn,
        checkIfDoctorLoggedIn,
        performUserLogin,
        userInfo,
        performDoctorUserLogin,
        getUserType,
        requestUserForgotPasswordRequest,
        requestDoctorForgotPasswordRequest
    } = useAuthentication();
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const aCall = async () => {
            const userType = getUserType();

            if (userType === '1') {
                await checkIfLoggedIn();
            } else if (userType === '2') {
                await checkIfDoctorLoggedIn();
            }

            setIsLoading(false);
        };

        aCall();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const updateDisplayNavigation = (setElement = null) => {
        if (!setElement) {
            setDisplayNavigation(!displayNavigation);
        }

        if (setElement) {
            setDisplayNavigation(setElement);
        }
    };
    return (
        <HashRouter>
            <Routes>
                <Route
                    path="/"
                    element={
                        <>
                            {!isLoading && <Header highlight={'Home'} user={userInfo} onSignOut={performUserLogout} />}
                            <Home authenticatedUser={userInfo} onSignOut={performUserLogout} isLoading={isLoading} user={userInfo}/>
                            <Footer />
                        </>
                    }
                />
                <Route
                    path="/confirm-account-patient"
                    element={
                        <>
                            {!isLoading && <Header />}
                            <ConfirmAccountPatient />
                            <Footer />
                        </>
                    }
                />
                <Route
                    path="/confirm-account-doctor"
                    element={
                        <>
                            {!isLoading && <Header />}
                            <ConfirmAccountDoctor />
                            <Footer />
                        </>
                    }
                />
                <Route
                    path="/old-doctor-profile"
                    element={
                        <DoctorProfilePage
                            displayNavigation={displayNavigation}
                            onUpdateDisplayNavigation={updateDisplayNavigation}
                        />
                    }
                />
                <Route path="/sign-up" element={<SignUpPage userType="patient" />} />
                <Route path="/sign-up-doctor" element={<SignUpPage userType="doctor" />} />
                <Route
                    path="/sign-in"
                    element={
                        <>
                            {!isLoading && (
                                <Header highlight={'SignIn'} user={userInfo} onSignOut={performUserLogout} />
                            )}
                            <SignIn onUserLogin={performUserLogin} checkIfLoggedIn={checkIfLoggedIn} />
                            <Footer />
                        </>
                    }
                />
                <Route
                    path="/sign-in-doctor"
                    element={
                        <>
                            {!isLoading && (
                                <Header highlight={'SignIn'} user={userInfo} onSignOut={performUserLogout} />
                            )}
                            <SignInDoctor
                                onUserLogin={performDoctorUserLogin}
                                checkIfLoggedIn={checkIfDoctorLoggedIn}
                            />
                            <Footer />
                        </>
                    }
                />
                <Route
                    path="/forgot-password"
                    element={
                        <>
                            {!isLoading && <Header user={userInfo} />}
                            <ForgotPassword onRequestPasswordReset={requestUserForgotPasswordRequest} user={userInfo} />
                            <Footer />
                        </>
                    }
                />
                <Route
                    path="/forgot-password-doctor"
                    element={
                        <>
                            {!isLoading && <Header user={userInfo} />}
                            <ForgotPasswordDoctor
                                onRequestPasswordReset={requestDoctorForgotPasswordRequest}
                                user={userInfo}
                            />
                            <Footer />
                        </>
                    }
                />

                <Route
                    path="/complete-user-password-reset"
                    element={
                        <>
                            {!isLoading && <Header />}
                            <ResetPassword user={userInfo} />
                            <Footer />
                        </>
                    }
                />
                <Route
                    path="/complete-doctor-password-reset"
                    element={
                        <>
                            {!isLoading && <Header />}
                            <ResetPasswordDoctor user={userInfo} />
                            <Footer />
                        </>
                    }
                />
                <Route
                    path="/forgot-password-doctor"
                    element={
                        <>
                            {!isLoading && (
                                <Header highlight={'SignIn'} user={userInfo} onSignOut={performUserLogout} />
                            )}
                            <ForgotPasswordDoctor
                                onUserLogin={performDoctorUserLogin}
                                checkIfLoggedIn={checkIfDoctorLoggedIn}
                            />
                            <Footer />
                        </>
                    }
                />
                <Route
                    path="/doctor-profile"
                    element={
                        <>
                            {!isLoading && <Header user={userInfo} onSignOut={performUserLogout} />}
                            {!isLoading && userInfo && userInfo?.usertype === 2 && (
                                <DoctorUserProfile user={userInfo} />
                            )}
                            {!isLoading && !userInfo && (
                                <CleanSession onSignOut={performUserLogout} userType="doctor" />
                            )}
                            <Footer />
                        </>
                    }
                />
                <Route
                    path="/appointment-manager-doctor"
                    element={
                        <>
                            {!isLoading && <Header user={userInfo} onSignOut={performUserLogout} />}
                            {!isLoading && userInfo && userInfo?.usertype === 2 && (
                                <DoctorAppointmentManager user={userInfo} />
                            )}
                            {!isLoading && !userInfo && (
                                <CleanSession onSignOut={performUserLogout} userType="doctor" />
                            )}
                            <Footer />
                        </>
                    }
                />
                <Route
                    path="/appointment-manager"
                    element={
                        <>
                            {!isLoading && <Header user={userInfo} onSignOut={performUserLogout} />}
                            {!isLoading && userInfo && userInfo?.usertype === 1 && (
                                <PatientAppointmentManager user={userInfo} />
                            )}
                            {!isLoading && !userInfo && (
                                <CleanSession onSignOut={performUserLogout} userType="patient" />
                            )}
                            <Footer />
                        </>
                    }
                />
                <Route
                    path="/user-profile"
                    element={
                        <>
                            {!isLoading && <Header user={userInfo} onSignOut={performUserLogout} />}
                            <DoctorUserProfile user={userInfo} />
                            {!isLoading && !userInfo && (
                                <CleanSession onSignOut={performUserLogout} userType="patient" />
                            )}
                            <Footer />
                        </>
                    }
                />
                <Route
                    path="/doctor-referral"
                    element={
                        <>
                            {!isLoading && <Header user={userInfo} onSignOut={performUserLogout} />}
                            <div>
                                <h1>Referencia de médico</h1>
                            </div>
                            <Footer />
                        </>
                    }
                />
                <Route
                    path="/who-we-are"
                    element={
                        <>
                            {!isLoading && (
                                <Header highlight={'WhoWeAre'} user={userInfo} onSignOut={performUserLogout} />
                            )}
                            <WhoWeAre />
                            <Footer />
                        </>
                    }
                />
                <Route
                    path="/what-is-doctolink"
                    element={
                        <>
                            {!isLoading && (
                                <Header highlight={'WhatIsDoctolink'} user={userInfo} onSignOut={performUserLogout} />
                            )}
                            <WhatIsDoctolink />
                            <Footer />
                        </>
                    }
                />
                {/*EXAMPLE FOR ALEX*/}
                <Route path="/side-by-side-example" element={<SideBySideImage />} />

                <Route
                    path="/search-results-2"
                    element={
                        <ResultsPage2
                            displayNavigation={displayNavigation}
                            onUpdateDisplayNavigation={updateDisplayNavigation}
                        />
                    }
                />
                <Route
                    path="/search-results"
                    element={
                        <>
                            {!isLoading && <Header user={userInfo} onSignOut={performUserLogout} />}
                            <div className="bg-dl-screen-lightblue py-12 lg:px-8 mx-auto px-4 relative sm:px-6">
                                <SearchResults />
                            </div>
                            <Footer />
                        </>
                    }
                />
                <Route
                    path="/doctor-results-2"
                    element={
                        <DoctorResultsPage2
                            displayNavigation={displayNavigation}
                            onUpdateDisplayNavigation={updateDisplayNavigation}
                        />
                    }
                />
                <Route
                    path="/doctor-results/:doctorId/:doctorName"
                    element={
                        <>
                            {!isLoading && <Header user={userInfo} onSignOut={performUserLogout} />}
                            <DoctorResultsTabsElement />
                            <Footer />
                        </>
                    }
                />
                <Route
                    path="/doctor-profile-details"
                    element={
                        <DoctorDetailsPage
                            displayNavigation={displayNavigation}
                            onUpdateDisplayNavigation={updateDisplayNavigation}
                        />
                    }
                />
                <Route
                    path="/doctor-results/:doctorId/:doctorName/appointment-motive"
                    element={
                        <>
                            {!isLoading && <Header user={userInfo} onSignOut={performUserLogout} />}

                            <SelectAppointment />
                            <Footer />
                        </>
                    }
                />
                <Route
                    path="/doctor-results/:doctorId/:doctorName/appointment-date"
                    element={
                        <>
                            {!isLoading && <Header user={userInfo} onSignOut={performUserLogout} />}
                            <div className={'flex justify-center m-7'}>
                                <SelectAppointmentDate />
                            </div>
                            <Footer />
                        </>
                    }
                />

                <Route
                    path="/:doctorId/:doctorName/appointment-success"
                    element={
                        <>
                            {!isLoading && <Header user={userInfo} onSignOut={performUserLogout} />}
                            <AppointmentSuccess />
                            <Footer />
                        </>
                    }
                />
                <Route
                    path="/appointment-creation"
                    element={
                        <AppointmentCreationPage
                            displayNavigation={displayNavigation}
                            onUpdateDisplayNavigation={updateDisplayNavigation}
                        />
                    }
                />
                <Route
                    path="/appointment-confirmation"
                    element={
                        <AppointmentConfirmationPage
                            displayNavigation={displayNavigation}
                            onUpdateDisplayNavigation={updateDisplayNavigation}
                        />
                    }
                />
                <Route
                    path="/patient-profile"
                    element={
                        <>
                            {!isLoading && <Header user={userInfo} onSignOut={performUserLogout} />}
                            <PatientProfilePage
                                displayNavigation={displayNavigation}
                                onUpdateDisplayNavigation={updateDisplayNavigation}
                            />
                            {!isLoading && !userInfo && (
                                <CleanSession onSignOut={performUserLogout} userType="patient" />
                            )}
                            <Footer />
                        </>
                    }
                />
                <Route
                    path="/doctor-review"
                    element={
                        <DoctorsReviewPage
                            displayNavigation={displayNavigation}
                            onUpdateDisplayNavigation={updateDisplayNavigation}
                        />
                    }
                />
                <Route
                    path="/about-us"
                    element={
                        <AboutUsPage
                            displayNavigation={displayNavigation}
                            onUpdateDisplayNavigation={updateDisplayNavigation}
                        />
                    }
                />
                <Route
                    path="/contact-us"
                    element={
                        <ContactUsPage
                            displayNavigation={displayNavigation}
                            onUpdateDisplayNavigation={updateDisplayNavigation}
                        />
                    }
                />
                <Route
                    path="/terms-and-conditions"
                    element={
                        <ContactUsPage
                            displayNavigation={displayNavigation}
                            onUpdateDisplayNavigation={updateDisplayNavigation}
                        />
                    }
                />
                <Route
                    path="/terms-and-conditions-doctor"
                    element={
                        <ContactUsPage
                            displayNavigation={displayNavigation}
                            onUpdateDisplayNavigation={updateDisplayNavigation}
                        />
                    }
                />
                <Route
                    path="/doctor-appointment-date"
                    element={
                        <DoctorAppointmenDateSelectionPage
                            displayNavigation={displayNavigation}
                            onUpdateDisplayNavigation={updateDisplayNavigation}
                        />
                    }
                />
            </Routes>
        </HashRouter>
    );
}

export default App;
