import { useState } from 'react';
import saveLanguageInProfile from '../api/saveLanguageInProfile';
import deleteLanguageInProfile from '../api/deleteLanguageInProfile';

export default function useProfileLanguages(querySecondarySpecialities) {
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [successBody, setSuccessBody] = useState('');
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [errorBody, setErrorBody] = useState('');

    const _createSuccessMessage = (message) => {
        setShowSuccessModal(true);
        setSuccessBody(message);
    };

    const _createErrorMessage = (errorMessage) => {
        setShowErrorModal(true);
        setErrorBody(errorMessage);
    };

    const executeSaveLanguageInProfile = async (selected) => {
        const attemptToSave = await saveLanguageInProfile(selected, _createErrorMessage);
        if (attemptToSave) {
            _createSuccessMessage('Nueva especialidad agregada a perfil.');
        }
    };

    const onCloseSuccessMessage = () => {
        setShowSuccessModal(false);
        setSuccessBody('');
        querySecondarySpecialities();
    };

    const closeErrorMessage = () => {
        setShowErrorModal(false);
        setErrorBody('');
    };

    const onDeleteLanguageInProfile = async (id) => {
        const attemptToDelete = await deleteLanguageInProfile(id, _createErrorMessage);
        if (attemptToDelete) {
            _createSuccessMessage('Nueva especialidad eliminada del perfil.');
        }
    };

    return {
        executeSaveLanguageInProfile,
        showSuccessModal,
        successBody,
        onCloseSuccessMessage,
        showErrorModal,
        errorBody,
        closeErrorMessage,
        onDeleteLanguageInProfile
    };
}
