import Countrydata from './Countrydata.json';

export const formattedCountryData = Countrydata.map((country) => ({
    id: country.code,
    label: country.name_es,
    value: country.name_es
}));


// Every new personal document should be added here to get the actual spanish label.
export const personalDocuments = {
    dui: 'DUI',
    passport: 'Pasaporte'
};

export const groupBy = (xs, key) =>{
    return xs.reduce(function(rv, x) {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
};