const ItemCard = ({ section }) => section.properties !== null ? (
    <section aria-labelledby="applicant-information-title">
    <div className="bg-white shadow sm:rounded-lg">
        <div className="px-4 py-5 sm:px-6">
            <h2 id="applicant-information-title" className="text-lg leading-6 font-medium text-dl-secondary">
                {section.name}
            </h2>
            
        </div>
        <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
            <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                <div className="sm:col-span-2"> 
                        {
                           Array.isArray(section.properties) ? 
                           (<ul className='list-inside list-disc'>
                                {section.properties.map((property, index) => (
                                    <li key={index} className='mt-1 mb-3'>
                                        <span className="mt-1 text-sm text-gray-500">
                                            {property}
                                        </span>
                                    </li>
                                ))}  
                            </ul>)
                            : <span className="mt-1 text-sm text-gray-500">{section.properties}</span>   
                        }
                </div> 
            </dl>
        </div> 
    </div>
</section>
) : null;

export default ItemCard;
