import { CalendarIcon, CollectionIcon } from '@heroicons/react/outline';

function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
}

const doctorAppointmentManagerNavigation = [
    { name: 'Citas Pendientes', href: '#', icon: CalendarIcon, current: false },
    { name: 'Historial', href: '#', icon: CollectionIcon, current: false }
];

export default function DoctorProfileNavigation(props) {
    const { selectedItem, updateNavigation } = props;

    doctorAppointmentManagerNavigation.forEach((element, index) => {
        doctorAppointmentManagerNavigation[index].current = element.name === selectedItem;
    });

    const _onLinkClick = (event, tabName) => {
        event.preventDefault();
        updateNavigation(tabName);
    };

    return (
        <aside className="bg-dl-screen-gray py-6 px-2 sm:px-6 lg:py-0 lg:px-0 lg:col-span-3">
            <nav className="space-y-1 ml-3 mt-5">
                {doctorAppointmentManagerNavigation.map((item) => (
                    <a
                        key={item.name}
                        href={item.href}
                        onClick={(e) => _onLinkClick(e, item.name)}
                        className={classNames(
                            item.current
                                ? 'bg-gray-50 text-dl-secondary hover:text-dl-secondary hover:bg-white'
                                : 'text-gray-900 hover:text-gray-900 hover:bg-gray-50',
                            'group rounded-md px-3 py-2 flex items-center text-sm font-medium'
                        )}
                        aria-current={item.current ? 'page' : undefined}
                    >
                        <item.icon
                            className={classNames(
                                item.current
                                    ? 'text-dl-secondary group-hover:text-dl-secondary'
                                    : 'text-gray-400 group-hover:text-gray-500',
                                'flex-shrink-0 -ml-1 mr-3 h-6 w-6'
                            )}
                            aria-hidden="true"
                        />
                        <span className="truncate">{item.name}</span>
                    </a>
                ))}
            </nav>
        </aside>
    );
}
