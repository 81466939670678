import { useEffect, useState } from "react";
import fetchAuthenticatedDoctor from "../../api/fetchAuthenticatedDoctor";
import fetchAuthenticatedUser from '../../api/fetchAuthenticatedUser';
import getUserProfile from "../api/getPersonalProfile";


const usePersonalProfile = (userType) => {
    const [userProfile, setUserProfile] = useState(null);
    const [errorMessage, setErrorMessage] = useState(false); 
    const [userId, setUserId] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    
   useEffect(() => {
    const getInitialData = async () => {
        try {
          const { id } = userType === 'patient' ? await fetchAuthenticatedUser() : await fetchAuthenticatedDoctor();
          setUserId(id);
          const userData = await getUserProfile(userType, id, setErrorMessage);
          setUserProfile(userData);
          setIsLoading(false);
        } catch (error) {
          console.error('Error doctor data', error);
        }
    };
    
    getInitialData();
  }, []);

  return {
    userProfile,
    errorMessage,
    userId,
    isLoading
  };
};

export default usePersonalProfile;

