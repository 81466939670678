import NavigationElements from "./pageElements/NavigationElements";
import AppointmentHistoryElement from "./pageElements/AppointmentHistoryElement";
import ScheduleAvailabilityElement from "./pageElements/ScheduleAvailabilityElement";
import DividerElement from "./uiElements/DividerElement";
import SectionHeading from "./uiElements/SectionHeading";

export default function AppointmentCreationPage(props) {

    const {displayNavigation, onUpdateDisplayNavigation} = props;

    return <>
        <NavigationElements
            displayNavigation={displayNavigation}
            onUpdateDisplayNavigation={onUpdateDisplayNavigation}
        />
        <SectionHeading title={'Historico de citas'} />
        <AppointmentHistoryElement />
        <div className={'my-8'}>
            <DividerElement />
        </div>
        <SectionHeading title={'Horarios disponibles'} />
        <ScheduleAvailabilityElement />
    </>
}
