import { useState } from 'react';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/solid';
import {
    add,
    eachDayOfInterval,
    endOfMonth,
    format,
    getDay,
    isEqual,
    isSameDay,
    isSameMonth,
    isToday,
    parse,
    parseISO,
    startOfTomorrow
} from 'date-fns';
import es from 'date-fns/locale/es';
import AppointmentItem from './appointmentItem/AppointmentItem';

function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
}

let colStartClasses = ['', 'col-start-2', 'col-start-3', 'col-start-4', 'col-start-5', 'col-start-6', 'col-start-7'];

export default function AppointmentCalendar(props) {
    const {
        availableAppointments,
        setSelectedAppointment,
        selectedAppointment,
        queryAppointmentHistory
    } = props;

    let tomorrow = startOfTomorrow();
    let [selectedDay, setSelectedDay] = useState(tomorrow);
    let [currentMonth, setCurrentMonth] = useState(format(tomorrow, 'MMM-yyyy'));
    let firstDayCurrentMonth = parse(currentMonth, 'MMM-yyyy', new Date());

    if (!availableAppointments) {
        return null;
    }

    let days = eachDayOfInterval({
        start: firstDayCurrentMonth,
        end: endOfMonth(firstDayCurrentMonth)
    });

    function previousMonth() {
        const firstDayPreviousMonth = add(firstDayCurrentMonth, { months: -1 });
        if (firstDayCurrentMonth >= tomorrow) {
            let startDate;
            let endDate;
            setCurrentMonth(format(firstDayPreviousMonth, 'MMM-yyyy'));
              // if the month selected is the same as tomorrow
            if (isSameMonth(firstDayPreviousMonth, tomorrow)) {
                startDate = tomorrow;
                endDate = endOfMonth(tomorrow);
            } else {
                startDate = firstDayPreviousMonth;
                endDate = endOfMonth(firstDayPreviousMonth);
            }

            queryAppointmentHistory(
                startDate.toISOString().slice(0, 10),
                endDate.toISOString().slice(0, 10)
            );
        }
    }

    function nextMonth() {
        const firstDayNextMonth = add(firstDayCurrentMonth, { months: 1 });
        setCurrentMonth(format(firstDayNextMonth, 'MMM-yyyy'));
        const startDate = firstDayNextMonth
        const endDate = endOfMonth(firstDayNextMonth);

        queryAppointmentHistory(
            startDate.toISOString().slice(0, 10),
            endDate.toISOString().slice(0, 10)
        );
    }

    let selectedDayAppointments = availableAppointments.filter((appointment) =>
        isSameDay(parseISO(`${appointment.date}T${appointment.appTime}`), selectedDay)
    );

    return (
        <div className="pt-16">
            <div className="max-w-md px-4 mx-auto sm:px-7 md:max-w-4xl md:px-6">
                <div className="md:grid md:grid-cols-2 md:divide-x md:divide-gray-200">
                    <div className="md:pr-14">
                        <div className="flex items-center">
                            <h2 className="flex-auto font-semibold text-gray-900 capitalize">
                                {format(firstDayCurrentMonth, 'MMMM yyyy', { locale: es })}
                            </h2>
                            <button
                                type="button"
                                onClick={previousMonth}
                                className="-my-1.5 flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500"
                            >
                                <span className="sr-only">Previous month</span>
                                <ChevronLeftIcon className="w-5 h-5" aria-hidden="true" />
                            </button>
                            <button
                                onClick={nextMonth}
                                type="button"
                                className="-my-1.5 -mr-1.5 ml-2 flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500"
                            >
                                <span className="sr-only">Next month</span>
                                <ChevronRightIcon className="w-5 h-5" aria-hidden="true" />
                            </button>
                        </div>
                        <div className="grid grid-cols-7 mt-10 text-xs leading-6 text-center text-gray-500">
                            <div>D</div>
                            <div>L</div>
                            <div>M</div>
                            <div>M</div>
                            <div>J</div>
                            <div>V</div>
                            <div>S</div>
                        </div>
                        <div className="grid grid-cols-7 mt-2 text-sm">
                            {days.map((day, dayIdx) => (
                                <div
                                    key={dayIdx}
                                    className={classNames(dayIdx === 0 && colStartClasses[getDay(day)], 'py-1.5')}
                                >
                                    <button
                                        type="button"
                                        onClick={() => setSelectedDay(day)}
                                        disabled={day < tomorrow}
                                        className={classNames(
                                            isEqual(day, selectedDay) && 'text-white',
                                            !isEqual(day, selectedDay) &&
                                                !isToday(day) &&
                                                isSameMonth(day, firstDayCurrentMonth) &&
                                                'text-gray-900',
                                            !isEqual(day, selectedDay) &&
                                                !isToday(day) &&
                                                !isSameMonth(day, firstDayCurrentMonth) &&
                                                'text-gray-400',
                                            isEqual(day, selectedDay) && isToday(day) && 'bg-red-500',
                                            isEqual(day, selectedDay) && !isToday(day) && 'bg-gray-900',
                                            !isEqual(day, selectedDay) && 'hover:bg-gray-200',
                                            (isEqual(day, selectedDay) || isToday(day)) && 'font-semibold',
                                            'mx-auto flex h-8 w-8 items-center justify-center rounded-full',
                                            day < tomorrow && 'cursor-not-allowed text-gray-300'
                                        )}
                                    >
                                        <time dateTime={format(day, 'yyyy-MM-dd')}>{format(day, 'd')}</time>
                                    </button>

                                    <div className="w-1 h-1 mx-auto mt-1">
                                        {availableAppointments.some((appointment) =>
                                            isSameDay(parseISO(appointment.date), day)
                                        ) && <div className="w-1 h-1 rounded-full bg-sky-500"></div>}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                    <section className="mt-12 md:mt-0 md:pl-14">
                        <h2 className="font-semibold text-gray-900">
                            Horarios disponibles para{' '}
                            <time dateTime={format(selectedDay, 'yyyy-MM-dd')}>
                                {format(selectedDay, 'MMM dd, yyy')}
                            </time>
                        </h2>
                        <p className='font-light text-gray-900'>Seleccione una hora</p>
                        <ol className="mt-4 space-y-1 text-sm leading-6 text-gray-500">
                            {selectedDayAppointments.length > 0 ? (
                                selectedDayAppointments.map((appointment) => (
                                    <AppointmentItem
                                        isSelected={
                                            selectedAppointment !== null &&
                                            appointment.date === selectedAppointment.date &&
                                            appointment.appTime === selectedAppointment.appTime
                                        }
                                        appointment={appointment}
                                        key={appointment.apptID}
                                        setSelectedAppointment={setSelectedAppointment}
                                    />
                                ))
                            ) : (
                                <p>No hay citas para este día.</p>
                            )}
                        </ol>
                    </section>
                </div>
            </div>
        </div>
    );
}