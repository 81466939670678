import { format, parseISO } from 'date-fns';

function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
}

export default function AppointmentItem(props) {
    const { appointment, setSelectedAppointment, isSelected } = props;

    let startDateTime = parseISO(`${appointment.date}T${appointment.appTime}`);

    const _onViewDetails = () => {
        setSelectedAppointment(appointment);
    };

    return (
        <li 
         onClick={_onViewDetails}
         className={`flex cursor-pointer items-center px-4 py-2 shadow space-x-4 group rounded-xl focus-within:bg-gray-100 hover:bg-gray-100 border
          ${isSelected ? 'border-blue-500' : null}`}
        >
            {/* In case someday users have avatars */}
            {/*<img src={meeting.imageUrl} alt="" className="flex-none w-10 h-10 rounded-full" />*/}
            <div className="flex-auto">
                <p className="text-gray-900">{appointment.userFullName}</p>
                <p className="mt-0.5">
                    <time dateTime={startDateTime}>{format(startDateTime, 'h:mm a')}</time>
                </p>
            </div>
        </li>
    );
}
