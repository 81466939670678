export default function Ads(props) {
    const { onClickAd, adsToDisplay } = props;

    return (
        <section className="bg-dl-screen-lightblue">
            <div className="mx-auto md:grid md:grid-cols-3">
                {adsToDisplay &&
                    adsToDisplay.length > 0 &&
                    adsToDisplay.map((ad) => (
                        <div key={ad.id} className="py-10 px-4 sm:px-6 md:flex md:flex-col md:py-14 md:px-5 lg:px-8">
                            <a
                                href={ad.link}
                                target="_blank"
                                rel="noopener nofollow noreferrer"
                                onClick={() => onClickAd(ad.id)}
                            >
                                <div className="md:flex-shrink-0">
                                    <img className="h-70 rounded-lg" src={ad.imageUrl} alt="ad" />
                                </div>
                            </a>
                        </div>
                    ))}
                {/*<div className="py-10 px-4 sm:px-6 md:py-14 md:px-5  lg:px-8">
                    <div className="md:flex-shrink-0">
                        <img className="h-70 rounded-lg" src="https://via.placeholder.com/550x250" alt="ad" />
                    </div>
                </div>
                <div className="py-10 px-4 sm:px-6 md:py-14 md:px-5  lg:px-8">
                    <div className="md:flex-shrink-0">
                        <img className="h-70 rounded-lg" src="https://via.placeholder.com/550x250" alt="ad" />
                    </div>
                </div>*/}
            </div>
        </section>
    );
}
