import NavigationElements from "./pageElements/NavigationElements";
import DoctorResultsTabsElement from "./pageElements/DoctorResultsTabsElement";

export default function DoctorResultsPage(props) {

    const {displayNavigation, onUpdateDisplayNavigation} = props;

    return <>
        <NavigationElements
            displayNavigation={displayNavigation}
            onUpdateDisplayNavigation={onUpdateDisplayNavigation}
        />
        <DoctorResultsTabsElement />
    </>
}
