import { useState } from 'react';
import Configurations from '../../common/utils/configurations';

export default function useConfirmAccountDoctor() {
    const [noNeedEmail, setNoNeedEmail] = useState(false);

    const executeValidation = async (identifier) => {
        if (!identifier) return false;

        try {
            const attemptToValidate = await fetch(
                `${Configurations.API_SERVER}/doctor-data/validate-email?id=${identifier}`,
                {
                    method: 'GET',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json'
                    }
                }
            );

            console.log(attemptToValidate);

            return attemptToValidate && attemptToValidate.status < 400;
        } catch (e) {}
    };
    const sendNewEmail = async (email) => {
        if (!email) return false;

        try {
            const attemptToSend = await fetch(
                `${Configurations.API_SERVER}/doctor-data/send-new-validation-email?email=${email}`,
                {
                    method: 'GET',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json'
                    }
                }
            );

            if (attemptToSend && attemptToSend.status === 401) {
                setNoNeedEmail(true);
                return true;
            }

            return attemptToSend && attemptToSend.status < 400;
        } catch (e) {
            return false;
        }
    };

    return { executeValidation, sendNewEmail, noNeedEmail, setNoNeedEmail };
}
