import { useForm } from 'react-hook-form';
import { useEffect, useState } from 'react';
import createAvailability from '../api/createAvailability';
import updateAvailability from '../api/updateAvailability';
import { format } from 'date-fns';

export default function useDoctorAvailability(queryAllDoctorAvailabilities) {
    const [isLoading, setIsLoading] = useState(true);
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [errorBody, setErrorBody] = useState('');
    const [successBody, setSuccessBody] = useState('');
    const [defaultValues, setDefaultValues] = useState({});
    const [selectedAvailability, setSelectedAvailability] = useState(null);

    const {
        register,
        handleSubmit,
        formState: { errors, isDirty },
        watch,
        control,
        reset,
        setValue
    } = useForm(defaultValues);

    useEffect(() => {
        setIsLoading(true);

        if (selectedAvailability) {
            if (typeof selectedAvailability.appTime === 'string') {
                const now = new Date();
                now.setHours(selectedAvailability.appTime.substr(0, selectedAvailability.appTime.indexOf(':')));
                now.setMinutes(selectedAvailability.appTime.substr(selectedAvailability.appTime.indexOf(':') + 1, 2));
                now.setSeconds(0);

                selectedAvailability.appTime = now;
            }
            setDefaultValues(selectedAvailability);
            reset(selectedAvailability);
            setValue('status', selectedAvailability.status ? 'true' : 'false', { shouldValidate: true });
            setValue('appTime', selectedAvailability.appTime, { shouldValidate: true });
            setValue('idSchedule', selectedAvailability.idSchedule.toString(), { shouldValidate: true });
        }

        setIsLoading(false);
    }, [reset, selectedAvailability, setValue]);

    const resetState = () => {
        setShowSuccessModal(false);
        setShowErrorModal(false);
        setErrorBody('');
        setSuccessBody('');
        setDefaultValues({});
        setSelectedAvailability(null);
        reset({ appTime: '', idSchedule: '' });
        setValue('status', '', { shouldValidate: true });
    };

    const _createErrorMessage = (errorMessage) => {
        setShowErrorModal(true);
        setErrorBody(errorMessage);
    };

    const closeErrorMessage = () => {
        setShowErrorModal(false);
        setErrorBody('');
    };

    const _createSuccessMessage = (errorMessage) => {
        setShowSuccessModal(true);
        setSuccessBody(errorMessage);
    };

    const saveAvailability = (data) => {
        data.appTime = format(data.appTime, 'HH:mm');
        data.idSchedule = parseInt(data.idSchedule);
        if (!data) {
            _createErrorMessage('No se pudo obtener información válida para guardar. Inténtelo de nuevo.');
            return;
        }

        if (JSON.stringify(defaultValues) === '{}') {
            executeCreateAvailability(data);
        } else {
            const adjustedData = { ...data, idAvailability: data.id, status: data.status === 'true' };
            doUpdateAvailability(adjustedData);
        }
    };

    const executeCreateAvailability = async (newAvailability) => {
        setIsLoading(true);
        const createNewAvailability = await createAvailability(newAvailability, _createErrorMessage);

        if (createNewAvailability) {
            _createSuccessMessage('¡Se ha guardado el registro con éxito!');
        }

        setIsLoading(false);
    };

    const doUpdateAvailability = async (updatedAvailabilityObject) => {
        setIsLoading(true);
        const executeUpdatedAvailability = await updateAvailability(updatedAvailabilityObject, _createErrorMessage);

        if (executeUpdatedAvailability) {
            _createSuccessMessage('¡Se ha actualizado el registro con éxito!');
        }

        setIsLoading(false);
    };

    const onCloseSuccessMessage = () => {
        resetState();
        queryAllDoctorAvailabilities();
    };

    return {
        register,
        handleSubmit,
        errors,
        watch,
        control,
        reset,
        setValue,
        showErrorModal,
        setShowErrorModal,
        closeErrorMessage,
        showSuccessModal,
        setShowSuccessModal,
        successBody,
        errorBody,
        setErrorBody,
        setSelectedAvailability,
        onCloseSuccessMessage,
        saveAvailability,
        isEditing: JSON.stringify(defaultValues) !== '{}',
        defaultValues,
        isLoading,
        resetState,
        isDirty
    };
}
