import create from 'zustand';

const useDoctorAppointmentStore = create((set) => ({
  selectedAppointment: {},
  selectionMode: null,
  setSelectedAppointment: (selectedAppointment) => set({ selectedAppointment }),
  setSelectionMode: (selectionMode) => set({ selectionMode })
}));

export default useDoctorAppointmentStore;
