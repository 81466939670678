import NavigationElements from "./pageElements/NavigationElements";
import AppointmentConfirmationElement from "./pageElements/AppointmentConfirmationElement";

export default function AppointmentConfirmationPage(props) {

    const {displayNavigation, onUpdateDisplayNavigation} = props;

    return <>
        <NavigationElements
            displayNavigation={displayNavigation}
            onUpdateDisplayNavigation={onUpdateDisplayNavigation}
        />
        <AppointmentConfirmationElement />
        </>
}
