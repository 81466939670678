// import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import ProfileNavigation from './components/profileNavigation/ProfileNavigation';
import PersonalInfo from '../personalProfile/PersonalProfile';
import ErrorBoundary from '../common/components/errorBoundaries/ErrorBoundary';

export default function UserProfile() {
    const [currentComponent, setCurrentComponent] = useState('Información Personal');

    // const navigate = useNavigate();
    // const sendToHome = () => navigate('/', { replace: true });

    return (
        <ErrorBoundary>
            <div className="lg:grid lg:grid-cols-12">
                <ProfileNavigation
                    selectedItem={currentComponent}
                    updateNavigation={(newComponent) => setCurrentComponent(newComponent)}
                />
                {currentComponent === 'Información Personal' && <PersonalInfo  userType='patient'/>}
            </div>
        </ErrorBoundary>
    );
}
