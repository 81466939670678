import { useNavigate } from "react-router-dom";
import useAppointmentStore from "../../common/state/useAppointmentStore";

const useSelectAppointmentMotive = () => {
  const navigate = useNavigate();
  const docData = useAppointmentStore((state) => state.doctorData);

  const onMotiveSelected = (motive) => {
    useAppointmentStore.getState().setSelectedMotive(motive);
    navigate(`/doctor-results/${docData.id}/${docData.fullName?.replace(' ', '-')}/appointment-date`)
  };

  return {
    docData,
    onMotiveSelected
  }
};

export default useSelectAppointmentMotive;
