import NavigationElements from "./pageElements/NavigationElements";
import PatientUserProfileElement from "../patientUserProfile/PatientUserProfile";

export default function PatientProfilePage(props) {

    const {displayNavigation, onUpdateDisplayNavigation } = props;

    return <>
        <NavigationElements
            displayNavigation={displayNavigation}
            onUpdateDisplayNavigation={onUpdateDisplayNavigation}
        />
        <PatientUserProfileElement />
    </>
}
