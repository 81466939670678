import { useState } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import AuthBO from '../businessObject/AuthBO';
import logo from '../../images/dl-logo.png';
import SuccessConfirmation from '../../common/components/successConfirmation/SuccessConfirmation';
import FailConfirmation from '../../common/components/failConfirmation/FailConfirmation';
import imgLogin from '../../images/dl-login.png';
import useResetPassword from './useResetPassword';

export default function ResetPassword(props) {
    const { user } = props;
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [successMessage, setSuccessMessage] = useState(null);
    const [failMessage, setFailMessage] = useState(null);

    const { executeReset } = useResetPassword();

    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const sendToHome = () => navigate('/', { replace: true });
    const sendToLogin = () => navigate('/sign-in', { replace: true });

    if (user) {
        sendToHome();
    }

    const identifier = searchParams.get('id');

    const _onExecuteNewPassword = async () => {
        try {
            if (AuthBO.validateNewPassword(password, confirmPassword)) {
                const checkResult = await executeReset(identifier, password);
                if (checkResult === true) {
                    setSuccessMessage('Proceso realizado con éxito. Redirigiendo a login.');
                    setTimeout(sendToLogin, 3000);
                } else {
                    setFailMessage(
                        'No se pudo realizar el proceso de cambio de contraseña. Inténtelo de nuevo o contacte a soporte.'
                    );
                    setTimeout(() => setFailMessage(null), 5000);
                }
            }
        } catch (e) {
            setFailMessage('Ocurrió un error. Inténtelo de nuevo.');
            setTimeout(() => setFailMessage(null), 5000);
        }
    };

    return (
        <>
            <div className="h-[calc(100vh_-_306px)] flex bg-dl-screen-gray">
                <div className="flex-1 flex flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
                    <div className="mx-auto w-full max-w-sm lg:w-96">
                        <div>
                            <Link to={'/'}>
                                <img className="w-72 mx-auto" src={logo} alt="DoctoLink" />
                            </Link>
                            <h2 className="mt-6 text-3xl font-extrabold text-dl-secondary">
                                Ingrese una nueva contraseña
                            </h2>
                            <p className="mt-2 text-sm text-dl-secondary">
                                <span className="font-medium text-dl-secondary">
                                    Escribe tu nueva contraseña y confirmala.
                                </span>
                            </p>
                            <div className="h-5 my-4 contents">
                                {Boolean(successMessage) && (
                                    <SuccessConfirmation
                                        message={successMessage}
                                        onClose={() => setSuccessMessage(null)}
                                    />
                                )}
                                {Boolean(failMessage) && (
                                    <FailConfirmation errorMessage={failMessage} onClose={() => setFailMessage(null)} />
                                )}
                            </div>
                        </div>

                        <div className="mt-10">
                            <div className="mt-6">
                                <form action="#" method="POST" className="space-y-6">
                                    <div>
                                        <label htmlFor="email" className="block text-sm font-medium text-dl-accent">
                                            Contraseña
                                        </label>
                                        <div className="mt-1">
                                            <input
                                                id="password"
                                                name="password"
                                                type="password"
                                                value={password}
                                                onInput={(event) => setPassword(event.target.value)}
                                                required
                                                className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-full shadow-sm placeholder-gray-400 focus:outline-none focus:ring-dl-primary-700 focus:border-dl-primary-700 sm:text-sm"
                                            />
                                        </div>
                                    </div>

                                    <div>
                                        <label htmlFor="email" className="block text-sm font-medium text-dl-accent">
                                            Confirmar Contraseña
                                        </label>
                                        <div className="mt-1">
                                            <input
                                                id="confirmPassword"
                                                name="confirmPassword"
                                                type="password"
                                                value={confirmPassword}
                                                onInput={(event) => setConfirmPassword(event.target.value)}
                                                required
                                                className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-full shadow-sm placeholder-gray-400 focus:outline-none focus:ring-dl-primary-700 focus:border-dl-primary-700 sm:text-sm"
                                            />
                                        </div>
                                    </div>

                                    <div>
                                        <button
                                            type="button"
                                            disabled={
                                                !password ||
                                                !confirmPassword ||
                                                !AuthBO.validateNewPassword(password, confirmPassword)
                                            }
                                            className="disabled:opacity-50 disabled:bg-dl-primary-500 w-full flex justify-center py-2 px-4 border border-transparent rounded-full shadow-sm text-sm font-medium text-white bg-dl-primary-900 hover:bg-dl-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-dl-primary-700"
                                            onClick={_onExecuteNewPassword}
                                        >
                                            Crear nueva contraseña
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="hidden lg:block relative w-0 flex-1">
                    <img className="absolute inset-0 h-full w-full object-cover" src={imgLogin} alt="" />
                </div>
            </div>
        </>
    );
}
