// Esto vendra del servicio web

const projects = [
    { name: 'Anestesiología', initials: 'A', href: '#', members: 0, bgColor: 'bg-dl-secondary' },
    { name: 'Cardiología Clínica', initials: 'CC', href: '#', members: 12, bgColor: 'bg-dl-secondary' },
    { name: 'Dermatología', initials: 'D', href: '#', members: 16, bgColor: 'bg-dl-secondary' },
    { name: 'Gastroenterología', initials: 'G', href: '#', members: 8, bgColor: 'bg-dl-secondary' },
    { name: 'Hematología', initials: 'H', href: '#', members: 0, bgColor: 'bg-dl-secondary' },
    { name: 'Medicina Interna', initials: 'MI', href: '#', members: 12, bgColor: 'bg-dl-secondary' },
    { name: 'Nefrología', initials: 'N', href: '#', members: 16, bgColor: 'bg-dl-secondary' },
    { name: 'Oftalmología', initials: 'O', href: '#', members: 8, bgColor: 'bg-dl-secondary' }
];

function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
}

export default function Specialty() {
    return (
        <div className="mb-10">
            <h2 className="text-2xl font-extrabold tracking-tight text-dl-accent sm:text-3xl pb-5">
                <span className="block">Lista de especialidades</span>
            </h2>
            <ul className="mt-3 grid grid-cols-1 gap-5 sm:gap-6 sm:grid-cols-2 lg:grid-cols-4">
                {projects.map((project) => (
                    <a  key={project.name} href={project.href} className="text-dl-accent font-medium hover:text-gray-600">
                        <li className="col-span-1 flex shadow-sm rounded-md">
                            <div
                                className={classNames(
                                    project.bgColor,
                                    'flex-shrink-0 flex items-center justify-center w-16 text-white text-sm font-medium rounded-l-md hover:bg-dl-accent'
                                )}
                            >
                                {project.initials}
                            </div>
                            <div className="flex-1 flex items-center justify-between border-t border-r border-b border-gray-200 bg-white rounded-r-md truncate">
                                <div className="flex-1 px-4 py-4 text-md truncate">{project.name}</div>
                            </div>
                        </li>
                    </a>
                ))}
            </ul>
        </div>
    );
}
