import { useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { updateDoctorPassword } from "../api/updateDoctorPassword";
import { updatePatientPassword } from "../api/updatePatientPassword";

const usePasswordForm = (userType) => {
    let newPassword = useRef({});
    const [ showSuccessModal, setShowSuccessModal ] = useState(false);
    const [ showErrorModal, setShowErrorModal ] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const {
        register,
        handleSubmit,
        formState: { errors },
        watch,
        reset
    } = useForm();

    newPassword.current = watch('newPassword', '');

    const validatePasswordConfirmation =
      (value) => value === newPassword.current;


    const onUpdatePassword = async({ oldPassword, newPassword}) => {
        setIsLoading(true);

        const onFail = () =>  {
          setShowErrorModal(true);
        };

        const res = userType == 'doctor' 
           ? await updateDoctorPassword({ oldPassword, newPassword }, onFail)
           : await updatePatientPassword({ oldPassword, newPassword }, onFail)

        setIsLoading(false);

        if (res) setShowSuccessModal(true);  
    };

    const onCloseErrorModal = () => setShowErrorModal(false);

    const onCloseSuccessModal = () => { 
      setShowSuccessModal(false);
      reset();
    };


    return {
      register,
      handleSubmit,
      errors,
      validatePasswordConfirmation,
      isLoading,
      showErrorModal,
      showSuccessModal,
      onUpdatePassword,
      onCloseErrorModal,
      onCloseSuccessModal
    };
};

export default usePasswordForm;
