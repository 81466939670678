import create from 'zustand';

const useAppointmentStore = create((set) => ({
  doctorData: {},
  selectedMotive: null,
  selectedAppointment: null, 
  setDoctorData: (doctorData) =>
    set({ doctorData }),
  setSelectedMotive: (selectedMotive) => set({ selectedMotive }),
  setSelectedAppointment: (selectedAppointment) => set({ selectedAppointment })
}));

export default useAppointmentStore;
