import { useNavigate } from "react-router-dom";
import AppointmentCalendar from "../../components/appointmentCalendar/AppointmentCalendar";
import usePendingAppointments from "../../doctorAppointmentManager/components/pendingAppointments/hooks/usePendingAppointments";

const Calendar = () => {
    const navigate = useNavigate();
    const {
        pendingAppointments,
        setSelectedAppointment,
        setSelectionMode
    } = usePendingAppointments();


    const viewAppointmentDetail = (appointment) => {
        setSelectedAppointment(appointment);
        setSelectionMode('complete');
        navigate('/appointment-manager-doctor');
    };

    const viewAppointmentReschedule = (appointment) => {
        setSelectedAppointment(appointment);
        setSelectionMode('reschedule');
        navigate('/appointment-manager-doctor');
    };

    return <div className="bg-dl-screen-white py-12 lg:px-8 mx-auto px-4 relative sm:px-6">
       <h2 className="text-2xl font-bold tracking-tight text-dl-accent sm:text-3xl pb-5">
                        <span className="block">Tu agenda de citas</span>
                    </h2>
        <AppointmentCalendar
            pendingAppointments={pendingAppointments}
            setSelectedAppointment={viewAppointmentDetail}
            onRescheduleAppointment={viewAppointmentReschedule}
        />
    </div>

};

export default Calendar;