import useAppointmentStore from "../../common/state/useAppointmentStore";


const useAppointmentSuccess = () => {
    const { 
        doctorData,
        selectedAppointment
    } =  useAppointmentStore(({ 
        doctorData,
        selectedAppointment,
      }) => ({
          doctorData,
          selectedAppointment
    }));


    return {
        doctorData,
        selectedAppointment
    }

};

export default useAppointmentSuccess;
