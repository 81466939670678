import Configurations from '../../common/utils/configurations';

export default function useResetPassword() {
    const executeReset = async (identifier, newPass) => {
        if (!identifier) return false;

        try {
            const attemptToValidate = await fetch(
                `${Configurations.API_SERVER}/auth/execute-password-reset?t=${identifier}&newPW=${newPass}`,
                {
                    method: 'GET',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json'
                    }
                }
            );

            return attemptToValidate && attemptToValidate.status < 400;
        } catch (e) {}
    };

    const executeResetDoctor = async (identifier, newPass) => {
        if (!identifier) return false;

        try {
            const attemptToValidate = await fetch(
                `${Configurations.API_SERVER}/doctor-data/execute-password-reset?t=${identifier}&newPW=${newPass}`,
                {
                    method: 'GET',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json'
                    }
                }
            );

            return attemptToValidate && attemptToValidate.status < 400;
        } catch (e) {}
    };

    return { executeReset, executeResetDoctor };
}
