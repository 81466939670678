import { useState, useEffect } from 'react';
import { signUp, getDocumentTypes } from '../api';
import { useNavigate } from 'react-router-dom';
import { personalDocuments } from '../common/utils/formattedData';

const useSignUp = (userType = 'patient') => {
   const navigate = useNavigate();
   const [ selectedDocument, setSelectedDocument ] = useState(null);
   const [ showSuccessModal, setShowSuccessModal ] = useState(false);
   const [ documentTypes, setDocumentTypes] = useState(null);
   const [ showErrorModal, setShowErrorModal ] = useState(false);
   const [ errorBody, setErrorBody ] = useState('');


   const onCloseSuccessModal = () => {
        setShowSuccessModal(false);
        navigate('/sign-in');
   };

   const onCloseErrorModal = () => {
        setShowErrorModal(false);
        setErrorBody('')
   };

   const onSignUp = async (data) => {
        if (selectedDocument.id) {
          const payload = {
            ...data,
            idDocumentTypes: selectedDocument.id
          };
          if (userType === 'doctor') {
            payload.nationality = data.nationality.label;
          }

          try {
              await signUp(userType, payload);  
              setShowSuccessModal(true);
          } catch (error) {
              console.error('There was an error on user sign up', error);
              switch (error.message) {
                  case 'EMAIL IN USE':
                      setErrorBody(`Una cuenta con el correo electrónico ingresado ya existe,
                                    por favor intentar de nuevo con otro correo electrónico`);
                      break;
                  default:
                      setErrorBody('Error al crear una cuenta, verifique los datos ingresados e intente nuevamente');
                      break;
              }
              setShowErrorModal(true);
          }
        } 
    };

    useEffect(() => {
        const fetchData = async () => {
          const data = await getDocumentTypes();
          const defaultDocument = data.find((doc) => doc.documentName === 'dui');
  
          defaultDocument && setSelectedDocument({
            ...defaultDocument,
            label: personalDocuments[defaultDocument.documentName] ?? defaultDocument.documentName,
            value: personalDocuments[defaultDocument.documentName] ?? defaultDocument.documentName
          });
          setDocumentTypes(data);
        };
  
        fetchData();
      }, []);

    return { 
      onSignUp,
      selectedDocument,
      setSelectedDocument,
      showSuccessModal,
      showErrorModal,
      errorBody,
      onCloseErrorModal,
      onCloseSuccessModal,
      documentTypes
    };
};

export default useSignUp;
