import { NewspaperIcon, PhoneIcon, SupportIcon } from '@heroicons/react/outline';

import img2 from './../../images/young-doctor-supporting-his-patient.jpg';

const supportLinks = [
    {
        name: 'Manual',
        href: '#',
        description:
            'Varius facilisi mauris sed sit. Non sed et duis dui leo, vulputate id malesuada non. Cras aliquet purus dui laoreet diam sed lacus, fames.',
        icon: PhoneIcon
    },
    {
        name: 'Preguntas frecuentes',
        href: '#',
        description:
            'Varius facilisi mauris sed sit. Non sed et duis dui leo, vulputate id malesuada non. Cras aliquet purus dui laoreet diam sed lacus, fames.',
        icon: SupportIcon
    },
    {
        name: 'Contáctanos',
        href: '#',
        description:
            'puedes escribirnos al siguiente correo:  doctolinkapp@gmail.com.',
        icon: NewspaperIcon
    }
];

export default function DoctorInformation() {
    return (
        <div className="bg-dl-screen-lightblue">
            {/* Header */}
            <div className="relative pb-32 bg-gray-800">
                <div className="absolute inset-0">
                    <img
                        // h-full object-cover
                        className="w-full h-full object-cover"
                        src={img2}
                        alt=""
                    />
                    <div className="absolute inset-0 bg-dl-primary-900 mix-blend-multiply" aria-hidden="true" />
                </div>
                <div className="relative max-w-7xl mx-auto py-24 px-4 sm:py-32 sm:px-6 lg:px-8">
                    <h1 className="text-4xl font-extrabold tracking-tight text-dl-accent md:text-5xl lg:text-6xl mb-5">
                        El equipo de Doctolink <br></br>está a tu servicio
                    </h1>
                    <div className="max-w-xl">
                        <p className="mt-10 max-w-3xl text-xl text-dl-accent bg-white/[.5] inline">
                            Conéctate para asistirte o darte más detalles.
                        </p>
                    </div>
                </div>
            </div>

            {/* Overlapping cards */}
            <section
                className="-mt-32 max-w-7xl mx-auto relative z-10 pb-20 px-4 sm:px-6 lg:px-8"
                aria-labelledby="contact-heading"
            >
                <h2 className="sr-only" id="contact-heading">
                    Soporte DoctoLink
                </h2>
                <div className="grid grid-cols-1 gap-y-20 lg:grid-cols-3 lg:gap-y-0 lg:gap-x-8">
                    {supportLinks.map((link) => (
                        <div key={link.name} className="flex flex-col bg-white rounded-2xl shadow-xl">
                            <div className="flex-1 relative pt-16 px-6 pb-8 md:px-8">
                                <div className="absolute top-0 p-5 inline-block bg-dl-accent rounded-xl shadow-lg transform -translate-y-1/2">
                                    <link.icon className="h-6 w-6 text-white" aria-hidden="true" />
                                </div>
                                <h3 className="text-xl font-medium text-dl-accent">{link.name}</h3>
                                <p className="mt-4 text-base text-gray-500">{link.description}</p>
                            </div>
                            <div className="p-6 bg-gray-50 rounded-bl-2xl rounded-br-2xl md:px-8">
                                <a
                                    href={link.href}
                                    className="text-base font-medium text-dl-secondary hover:text-dl-secondary/[.8]"
                                >
                                    Consultar<span aria-hidden="true"> &rarr;</span>
                                </a>
                            </div>
                        </div>
                    ))}
                </div>
            </section>
        </div>
    );
}
