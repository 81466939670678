import NavigationElements from "./pageElements/NavigationElements";
import SearchResultsElement from "./pageElements/SearchResultsElement";

import FooterElement from "./pageElements/FooterElement";

export default function ResultsPage(props) {
    const {displayNavigation, onUpdateDisplayNavigation} = props;

    return <>
        <NavigationElements
            displayNavigation={displayNavigation}
            onUpdateDisplayNavigation={onUpdateDisplayNavigation}
        />
        <div className="bg-dl-screen-lightblue py-12 lg:px-8 mx-auto px-4 relative sm:px-6">
            <SearchResultsElement/>
        </div>
        
        {/* Footer */}
        <FooterElement />
    </>
}
