import AppointmentCalendar from '../../../components/appointmentCalendar/AppointmentCalendar';
import usePendingAppointments from './hooks/usePendingAppointments';
import CustomRedAlert from '../../../common/components/customRedAlert/CustomRedAlert';
import AppointmentDetails from './components/appointmentDetails/AppointmentDetails';
import { useState } from 'react';

export default function PendingAppointments() {
    const {
        pendingAppointments,
        errorMessage,
        setErrorMessage,
        setSelectedAppointment,
        selectedAppointment,
        queryAppointmentHistory,
        selectionMode,
        setSelectionMode
    } = usePendingAppointments();

    const _viewAppointmentToComplete = (selectedAppointment) => {
        setSelectedAppointment(selectedAppointment);
        setSelectionMode('complete');
    };

    const _viewAppointmentToReschedule = (selectedAppointment) => {
        setSelectedAppointment(selectedAppointment);
        setSelectionMode('reschedule');
    };

    const _closeAppointmentView = () => {
        setSelectedAppointment({});
        setSelectionMode(null);
    };

    const _onSuccessfulSave = () => {
        _closeAppointmentView();
        queryAppointmentHistory();
    };

    return (
        <div className="space-y-6 sm:px-6 lg:px-0 lg:col-span-9 min-h-[80vh] pb-12">
            {JSON.stringify(selectedAppointment) === '{}' && (
                <AppointmentCalendar
                    pendingAppointments={pendingAppointments}
                    setSelectedAppointment={_viewAppointmentToComplete}
                    onRescheduleAppointment={_viewAppointmentToReschedule}
                />
            )}

            {JSON.stringify(selectedAppointment) !== '{}' && (
                <AppointmentDetails
                    appointment={selectedAppointment}
                    selectionMode={selectionMode}
                    onCancel={_closeAppointmentView}
                    onSuccessfulSave={_onSuccessfulSave}
                />
            )}

            {Boolean(errorMessage) && (
                <CustomRedAlert
                    open={true}
                    title={`Ocurrió un error.`}
                    bodyText={errorMessage}
                    onClose={() => setErrorMessage(null)}
                />
            )}
        </div>
    );
}
